import parse from "html-react-parser";
import ReactDOMServer from "react-dom/server";

// originalMessageからtargetMessageを検索し、ヒットした場合targetColorの色に変更する
// targetMessageはスペース区切りで複数のワードを与えられる
export const createColorSpannedString = (
  originalMessage,
  targetMessage,
  removeFullSpace = false,
  targetColor = "red"
) => {
  if (
    originalMessage !== undefined &&
    originalMessage !== "" &&
    targetMessage !== undefined &&
    targetMessage !== ""
  ) {
    const targetWordArray = targetMessage.split(/\s+/);
    const pattern = new RegExp(`(${targetWordArray.join("|")})`, "gi");

    const splitText = originalMessage.split(pattern);

    return splitText.map((str, index) => {
      // 全角スペースが続くと自動改行が働かないので、改行の可能性がある場合フラグを立てて半角スペースに置き換える
      const formattedStr = removeFullSpace ? str.replace(/[　]+/g, " ") : str;
      // 奇数indexはマッチした文字列
      if (index % 2 === 1) {
        return (
          <span key={index} style={{ color: targetColor }}>
            {formattedStr}
          </span>
        );
      } else {
        return <span key={index}>{formattedStr}</span>;
      }
    });
  } else {
    return originalMessage;
  }
};

export const stripHtmlTags = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

export const extractSurroundingText = (text, searchText) => {
  const regex = new RegExp(searchText, "i");
  const index = text.search(regex);
  if (index === -1) {
    return null; // 検索文字列が見つからない場合は null を返す
  }

  const startIndex = Math.max(0, index - 5);
  let surroundingText = text.substring(startIndex, text.length);

  if (startIndex !== 0) {
    surroundingText = `...${surroundingText}`; // 文字列の先頭に ... を追加する
  }

  return surroundingText;
};

export const extractFirstSurroundingText = (text, targetMessage) => {
  if (
    text !== undefined &&
    text !== "" &&
    targetMessage !== undefined &&
    targetMessage !== ""
  ) {
    const targetWordArray = targetMessage.split(/\s+/);
    const extractedArray = targetWordArray.map((targetText) => {
      return extractSurroundingText(text, targetText);
    });
    return extractedArray.find((value) => value !== null);
  } else {
    return "";
  }
};

export const createColorSpannedHTML = (
  originalHTML,
  targetMessage,
  targetColor = "red"
) => {
  if (
    originalHTML !== undefined &&
    originalHTML !== "" &&
    targetMessage !== undefined &&
    targetMessage !== ""
  ) {
    const options = {
      replace: (node) => {
        // パースしたhtmlの中身についてcreateColorSpannedStringによるハイライトを行う
        if (
          node.type === "text" &&
          node.parent &&
          node.parent.name !== "style"
        ) {
          const content = node.data;
          const colored = createColorSpannedString(
            content,
            targetMessage,
            false,
            targetColor
          );
          return <span>{colored}</span>;
        }
      },
    };

    const parsed = parse(originalHTML, options);
    // htmlをStringに変換して返す
    return ReactDOMServer.renderToStaticMarkup(parsed);
  } else {
    return originalHTML;
  }
};
