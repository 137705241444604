import React from 'react'
import {connect, store} from 'react-redux'
import {CloseCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Table,
    Tabs,
    Input,
    Collapse,
    Radio,
    Tree,
    Spin,
    Row,
    Col,
    Tooltip,
    Button,
    Popconfirm,
    Tag,
} from 'antd';
import log from 'components/utils/Logger.js'
import * as Actions from '../../redux/actions';
import AddGroupModal from './AddGroupModal';

const TreeNode = Tree.TreeNode;
const Search = Input.Search;





const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};


class SelectTargetGroupList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ModalText: 'Content of the modal',
            visible: props.visible,
            expandedKeys: [],
            searchValue: '',
            autoExpandParent: true,
            checkedKeys: props.checkedKeys,
            checkedRows: props.checkedRows,
            type: props.type,
            groupClusterRowArray: props.checkedRows,
            groupClusterKeyArray: props.checkedKeys,
            targetObject: props.targetObject,

            loading: props.loading
        }
    }

    componentWillMount() {
    }


    componentWillReceiveProps(nextProps) {
        const {group} = {...this.props};
        if(nextProps.checkedKeys){
            this.setState({
                groupClusterKeyArray : nextProps.checkedKeys,
            })
        }
        if(nextProps.checkedRows){
            this.setState({
                groupClusterRowArray: nextProps.checkedRows
            })
        }
        this.setState({
            type: nextProps.type,
            targetObject: nextProps.targetObject,
            loading: nextProps.loading
        })
    }

    handleEditOk = (uuidArray,valueArray) => {

        const keyArray = this.state.groupClusterKeyArray;
        const rowArray = this.state.groupClusterRowArray;
        if(uuidArray.length === 0){
            keyArray.splice(this.state.editingIndex,1);
            rowArray.splice(this.state.editingIndex,1);
            this.setState({
                editGroupModalIsOpen :false,
                rowData: undefined,
                editingIndex: undefined
            })
            this.props.setObject(keyArray, rowArray);
            return;
        }

        let key = this.state.type==="target"? "target_groups":"permission_groups";
        keyArray.splice(this.state.editingIndex,1, {[key]: uuidArray});
        rowArray.splice(this.state.editingIndex,1, {[key]: valueArray});

        log.debug(keyArray);

        this.setState({
            editGroupModalIsOpen :false,
            rowData: undefined,
            editingIndex: undefined
        })

        this.props.setObject(keyArray, rowArray);
    };

    handleOk = (uuidArray,valueArray) => {
        log.debug(uuidArray);

        const keyArray = this.state.groupClusterKeyArray;
        const rowArray = this.state.groupClusterRowArray;
        log.debug(rowArray);
        log.debug(keyArray);
        let key = this.state.type==="target"? "target_groups" : "permission_groups" ;
        keyArray.push({
            [key]:uuidArray
        });
        rowArray.push({
            [key]:valueArray
        });

        this.setState({
            addGroupModalIsOpen: false
        });
        this.props.setObject(keyArray, rowArray);
    }


    handleCancel = () => {
        this.setState({
            addGroupModalIsOpen: false,
            editGroupModalIsOpen: false
        });
    }

    handleOpenEditGroupModal = (record,index) => {
        let checkedKey = [];
        if(record.trees.length !== 0){
            record.trees.forEach((value) => {
                if(this.state.type==="target") {
                    value.target_groups.forEach((value2) => {
                        checkedKey.push(value2);
                    })
                }else{
                    value.permission_groups.forEach((value2) => {
                        checkedKey.push(value2);
                    })
                }
            })
        }

        this.setState({
            rowData: record,
            editingIndex: index,
            editGroupModalIsOpen :true,
            checkedKeys: checkedKey
        })
    };

    handleDeleteGroup = (record,index) => {
        const keyArray = this.state.groupClusterKeyArray;
        const rowArray = this.state.groupClusterRowArray;

        keyArray.splice(index,1);
        rowArray.splice(index,1);

        this.props.setObject(keyArray, rowArray);
    };

    render() {
        const {visible, confirmLoading, ModalText, searchValue, expandedKeys, autoExpandParent} = this.state;
        const columns = [
            {
                title: '選択グループ',
                dataIndex: 'select_group',
                key: 'select_group',
                render: (text, record) => {
                    return(
                        <div  key={record.uuid}>
                            {record.user_count === "0" ?
                                <Tag icon={<CloseCircleOutlined />} color="error">
                                    対象者 0名
                                </Tag>: null
                            }
                            {record.cluster_name}
                        </div>
                    )

                }
            },
            {
                dataIndex: 'options',
                key: 'options',
                fixed: "right",
                width: "100px",
                render: (text, record,index) => {
                    return (
                        <Row type="flex" justify="space-around" align="middle">
                            <Col>
                                <Tooltip placement="topLeft" title="編集">
                                    <Button type="default" shape="circle" icon={<EditOutlined />}
                                            onClick={() => {
                                                this.handleOpenEditGroupModal(record,index)
                                            }}/>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Popconfirm
                                    title={<span>グループリストからグループを削除します。<br/>
                                    <strong>削除すると復元することはできません。</strong></span>}
                                    onConfirm={() => this.handleDeleteGroup(record,index)}
                                    onCancel=""
                                    placement="left"
                                    okText="削除" cancelText="キャンセル">
                                    <Tooltip placement="topLeft" title="削除">
                                        <Button type="default" shape="circle" icon={<DeleteOutlined />}/>
                                    </Tooltip>
                                </Popconfirm>
                            </Col>
                        </Row>
                    );
                }
            }
        ];


        return (
            <div id="group-tree-container" className="wrapper" alt="グループ">
                <div className="group-tree-container-title-wrapper title-wrapper">
                    <h2>対象グループ</h2>
                    <Button type="primary" className="button"
                            onClick={() => this.setState({addGroupModalIsOpen: true})} icon={<PlusCircleOutlined />}>
                        追加
                    </Button>
                </div>
                <Table
                    rowKey="uuid"
                    loading={this.props.loading}
                    className="general-table"
                    locale={{
                        filterTitle: 'フィルタ',
                        filterConfirm: '確定',
                        filterReset: 'リセット',
                        emptyText: '該当するものはありません',
                    }}
                    pagination={this.state.pagination}
                    bordered={true}
                    size="middle"
                    dataSource={this.state.groupClusterRowArray}
                    columns={columns}/>

                <AddGroupModal
                    isEditMode={false}
                    visible={this.state.addGroupModalIsOpen}
                    handleOk={this.handleOk}
                    handleCancel={this.handleCancel}
                    targetObject={this.state.targetObject}  />

                <AddGroupModal
                    isEditMode={true}
                    visible={this.state.editGroupModalIsOpen}
                    handleOk={this.handleEditOk}
                    handleCancel={this.handleCancel}
                    targetObject={this.state.targetObject}
                    checkedKeys={this.state.checkedKeys}
                    rowData={this.state.rowData}
                />
            </div>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectTargetGroupList)


SelectTargetGroupList.defaultProps = {
    checkedKeys : [],
    checkedRows : []
}
