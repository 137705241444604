import React from 'react'
import PropTypes from 'prop-types';
import {connect, store} from 'react-redux'
import {
    EnvironmentOutlined,
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Modal, Tabs, Radio, Button, Popconfirm} from 'antd';

import log from 'components/utils/Logger.js'

import * as GlobalConfig from '../../constants/GlobalConfig'
import * as Actions from '../../redux/actions'
import moment from 'moment';
import 'moment/locale/ja';
import NotificationList from 'containers/notification/NotificationList'
import SelectTargetFromGroup from 'containers/common/SelectTargetFormGroup'
import SelectTargetFromUsers from 'containers/common/SelectTargetFormUsers'
import SelectTargetPreview from 'containers/common/SelectTargetPreview'
import SelectTargetFormIPAddress from "./SelectTargetIPAddress"
import SelectTargetGroupList from 'containers/common/SelectTargetGroupList'
import MyTargetModal from "./MyTargetModal";
import MyTargetEditModal from "./MyTargetEditModal";

moment.locale('ja')
const TabPane = Tabs.TabPane;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;


const mapStateToProps = (state, props) => {
    return {
        target: state.target,
        targetCreate: state.targetCreate,
        targetUpdate: state.targetUpdate,
        targetDelete: state.targetDelete,
        targetCopy: state.targetCopy,
        userSearch: state.userSearch,
        userSearchTarget: state.userSearchTarget,

        targetUserAdd: state.targetUserAdd,
        targetUserAddAll: state.targetUserAddAll,
        targetUserAddCSV: state.targetUserAddCSV,


        targetUserDelete: state.targetUserDelete,
        targetUserDeleteAll: state.targetUserDeleteAll,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTarget: (uuid) => {
            dispatch(Actions.AdminConnection.target.search.request(uuid));
        },
        copyTarget: (uuid) => {
            dispatch(Actions.AdminConnection.target.copy.request(uuid));
        },
        createTarget: (data) => {
            dispatch(Actions.AdminConnection.target.create.request(data));
        },
        createMyTarget: (data) => {
            dispatch(Actions.http.connection.target.myTarget.create(data));
        },
        updateTarget: (uuid, data) => {
            dispatch(Actions.AdminConnection.target.update.request(uuid, data));
        },
        addUserTarget: (uuid, data) => {
            dispatch(Actions.AdminConnection.target.adduser.request(uuid, data));
        },
        addUserAllTarget: (uuid, data) => {
            dispatch(Actions.AdminConnection.target.addall.request(uuid, data));
        },
        addUserCsvTarget: (uuid, data) => {
            dispatch(Actions.AdminConnection.target.addallcsv.request(uuid, data));
        },
        deleteUserTarget: (uuid, data) => {
            dispatch(Actions.AdminConnection.target.deleteuser.request(uuid, data));
        },
        deleteUserAllTarget: (uuid) => {
            dispatch(Actions.AdminConnection.target.deleteuserall.request(uuid));
        },
        deleteTarget: (uuid) => {
            dispatch(Actions.AdminConnection.target.delete.request(uuid));
        }
    }
};


class SelectTargetForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            type: props.type, //カテゴリ (1: お知らせ 2: アンケート 3: モジュールリンク)

            self_uuid: props.self_uuid,
            foreign_uuid: props.foreign_uuid,

            loading: true,
            expandedKeys: [],
            groupCheckedKeys: [],
            groupCheckedRows: [],
            userCheckedKeys: [],
            userCheckedRows: [],
            networkData: "",
            object: [],
            userNetworkKeys: [],
            currentActiveKey: "2",
            etag: 0
        }
    }


    componentWillReceiveProps(nextProps) {
        const {
            target,
            targetCreate,
            targetUpdate,
            targetCopy,
            targetUserAdd,
            targetUserDelete,
            targetUserDeleteAll,
            targetUserAddAll,
            targetUserAddCSV
        } = this.props;

        this.setState({
            visible: nextProps.visible,
            type: nextProps.type,
            key: nextProps.key
        });
        if (nextProps.visible !== this.props.visible) {
            console.log(nextProps);
            if (nextProps.visible) {
                this.setState({
                    foreign_uuid: nextProps.foreign_uuid,
                    self_uuid: nextProps.self_uuid,
                    row_self_uuid: nextProps.self_uuid,
                    type: nextProps.type
                }, () => {
                    console.log(nextProps.self_uuid)
                    if (nextProps.self_uuid) {
                        this.setState({
                            loading: true
                        })
                        this.props.copyTarget(nextProps.self_uuid);
                    } else {
                        this.props.createTarget(this.generatePostData());

                        this.setState({
                            loading: false
                        })
                    }
                });
            } else {
                this.setState({
                    etag: 0
                })
            }
        }


        if (nextProps.targetCopy !== targetCopy) {
            if (!nextProps.targetCopy.meta.fetch) {
                if (!nextProps.targetCopy.error) {
                    if (nextProps.targetCopy.payload !== null) {
                        const groupCheckedKeys = nextProps.targetCopy.payload.result.group_clusters.map((value, index) => {
                            return {target_groups: value.all_group_ids};
                        });
                        const groupCheckedRows = nextProps.targetCopy.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });
                        const networkData = nextProps.targetCopy.payload.result.remote_addresses;

                        this.setState({
                            loading: nextProps.targetCopy.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            rowTargetData: nextProps.targetCopy.payload.result,
                            networkData,
                            self_uuid: nextProps.targetCopy.payload.result.uuid ? nextProps.targetCopy.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.targetCopy.payload.result.foreign_uuid ? nextProps.targetCopy.payload.result.foreign_uuid : this.props.foreign_uuid
                        },()=>{
                            if(this.state.after_save){
                                //my-targetに保存したものとは別のコピー済みのものを利用する
                                this.setState({
                                    after_save: false
                                })
                                //対象者選択完了を実施
                                this.handleOk();
                            }
                        })
                    }
                } else {
                    //権限エラーなので強制クローズ
                    this.props.handleCancel();
                }
            } else {
                this.setState({
                    loading: nextProps.targetCopy.meta.fetch
                })
            }
        }

        if (nextProps.targetCreate !== targetCreate) {
            if (!nextProps.targetCreate.meta.fetch) {
                if (!nextProps.targetCreate.error) {
                    if (nextProps.targetCreate.payload !== null) {
                        const groupCheckedKeys = nextProps.targetCreate.payload.result.group_clusters.map((value, index) => {
                            return {target_groups: value.all_group_ids};
                        });
                        const groupCheckedRows = nextProps.targetCreate.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });
                        const networkData = nextProps.targetCreate.payload.result.remote_addresses;

                        this.setState({
                            loading: nextProps.targetCreate.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            networkData,
                            rowTargetData: nextProps.targetCreate.payload.result,

                            self_uuid: nextProps.targetCreate.payload.result.uuid ? nextProps.targetCreate.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.targetCreate.payload.result.foreign_uuid ? nextProps.targetCreate.payload.result.foreign_uuid : this.props.foreign_uuid
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.targetCreate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.targetCreate.meta.fetch
                })
            }
        }

        if (nextProps.targetUpdate !== targetUpdate) {
            if (!nextProps.targetUpdate.meta.fetch) {
                if (!nextProps.targetUpdate.error) {
                    if (nextProps.targetUpdate.payload !== null) {
                        //handleOK
                        const groupCheckedKeys = nextProps.targetUpdate.payload.result.group_clusters.map((value, index) => {
                            return {target_groups: value.all_group_ids};
                        });
                        const groupCheckedRows = nextProps.targetUpdate.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });
                        const networkData = nextProps.targetUpdate.payload.result.remote_addresses;


                        this.setState({
                            loading: nextProps.targetUpdate.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            networkData,
                            rowTargetData: nextProps.targetUpdate.payload.result,
                            //uuidがないとそもそもpermissionを読みに行けないのでredundant
                            //uuid: "",
                            self_uuid: nextProps.targetUpdate.payload.result.uuid ? nextProps.targetUpdate.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.targetUpdate.payload.result.foreign_uuid ? nextProps.targetUpdate.payload.result.foreign_uuid : this.props.foreign_uuid
                        })
                    }
                } else {
                    const old_networkData = this.state.oldNetworkData;
                    this.setState({
                        networkData: old_networkData,
                        oldNetworkData: "",
                        loading: nextProps.targetUpdate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.targetUpdate.meta.fetch
                })
            }
        }


        if (nextProps.targetUserAdd !== targetUserAdd) {
            if (!nextProps.targetUserAdd.meta.fetch) {
                if (!nextProps.targetUserAdd.error) {
                    if (nextProps.targetUserAdd.payload !== null) {
                        //handleOK
                        const groupCheckedKeys = nextProps.targetUserAdd.payload.result.group_clusters.map((value, index) => {
                            return {target_groups: value.all_group_ids};
                        });
                        const groupCheckedRows = nextProps.targetUserAdd.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });
                        const networkData = nextProps.targetUserAdd.payload.result.remote_addresses;

                        this.setState({
                            loading: nextProps.targetUserAdd.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            networkData,
                            rowTargetData: nextProps.targetUserAdd.payload.result,
                            //uuidがないとそもそもpermissionを読みに行けないのでredundant
                            //uuid: "",
                            etag: this.state.etag + 1,
                            self_uuid: nextProps.targetUserAdd.payload.result.uuid ? nextProps.targetUserAdd.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.targetUserAdd.payload.result.foreign_uuid ? nextProps.targetUserAdd.payload.result.foreign_uuid : this.props.foreign_uuid
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.targetUserAdd.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.targetUserAdd.meta.fetch
                })
            }
        }

        if (nextProps.targetUserAddAll !== targetUserAddAll) {
            if (!nextProps.targetUserAddAll.meta.fetch) {
                if (!nextProps.targetUserAddAll.error) {
                    if (nextProps.targetUserAddAll.payload !== null) {
                        //handleOK
                        const groupCheckedKeys = nextProps.targetUserAddAll.payload.result.group_clusters.map((value, index) => {
                            return {target_groups: value.all_group_ids};
                        });
                        const groupCheckedRows = nextProps.targetUserAddAll.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });
                        const networkData = nextProps.targetUserAddAll.payload.result.remote_addresses;

                        this.setState({
                            loading: nextProps.targetUserAddAll.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            networkData,
                            etag: this.state.etag + 1,
                            rowTargetData: nextProps.targetUserAddAll.payload.result,
                            self_uuid: nextProps.targetUserAddAll.payload.result.uuid ? nextProps.targetUserAddAll.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.targetUserAddAll.payload.result.foreign_uuid ? nextProps.targetUserAddAll.payload.result.foreign_uuid : this.props.foreign_uuid
                        })

                    }
                } else {
                    this.setState({
                        loading: nextProps.targetUserAddAll.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.targetUserAddAll.meta.fetch
                })
            }
        }

        if (nextProps.targetUserAddCSV !== targetUserAddCSV) {
            if (!nextProps.targetUserAddCSV.meta.fetch) {
                if (!nextProps.targetUserAddCSV.error) {
                    if (nextProps.targetUserAddCSV.payload !== null) {
                        //handleOK
                        const groupCheckedKeys = nextProps.targetUserAddCSV.payload.result.group_clusters.map((value, index) => {
                            return {target_groups: value.all_group_ids};
                        });
                        const groupCheckedRows = nextProps.targetUserAddCSV.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });
                        const networkData = nextProps.targetUserAddCSV.payload.result.remote_addresses;

                        this.setState({
                            loading: nextProps.targetUserAddCSV.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            networkData,
                            etag: this.state.etag + 1,
                            rowTargetData: nextProps.targetUserAddCSV.payload.result,
                            self_uuid: nextProps.targetUserAddCSV.payload.result.uuid ? nextProps.targetUserAddCSV.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.targetUserAddCSV.payload.result.foreign_uuid ? nextProps.targetUserAddCSV.payload.result.foreign_uuid : this.props.foreign_uuid
                        })

                    }
                } else {
                    this.setState({
                        loading: nextProps.targetUserAddCSV.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.targetUserAddCSV.meta.fetch
                })
            }
        }

        if (nextProps.targetUserDelete !== targetUserDelete) {
            if (!nextProps.targetUserDelete.meta.fetch) {
                if (!nextProps.targetUserDelete.error) {
                    if (nextProps.targetUserDelete.payload !== null) {
                        //handleOK
                        const groupCheckedKeys = nextProps.targetUserDelete.payload.result.group_clusters.map((value, index) => {
                            return {target_groups: value.all_group_ids};
                        });
                        const groupCheckedRows = nextProps.targetUserDelete.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });
                        const networkData = nextProps.targetUserDelete.payload.result.remote_addresses;

                        this.setState({
                            loading: nextProps.targetUserDelete.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            networkData,
                            etag: this.state.etag + 1,
                            rowTargetData: nextProps.targetUserDelete.payload.result,
                            //uuidがないとそもそもpermissionを読みに行けないのでredundant
                            //uuid: "",
                            self_uuid: nextProps.targetUserDelete.payload.result.uuid ? nextProps.targetUserDelete.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.targetUserDelete.payload.result.foreign_uuid ? nextProps.targetUserDelete.payload.result.foreign_uuid : this.props.foreign_uuid
                        })
                    } else {
                        this.setState({
                            loading: nextProps.targetUserDelete.meta.fetch
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.targetUserDelete.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.targetUserDelete.meta.fetch
                })
            }
        }


        if (nextProps.targetUserDeleteAll !== targetUserDeleteAll) {
            if (!nextProps.targetUserDeleteAll.meta.fetch) {
                if (!nextProps.targetUserDeleteAll.error) {
                    if (nextProps.targetUserDeleteAll.payload !== null) {
                        //handleOK
                        const groupCheckedKeys = nextProps.targetUserDeleteAll.payload.result.group_clusters.map((value, index) => {
                            return {target_groups: value.all_group_ids};
                        });
                        const groupCheckedRows = nextProps.targetUserDeleteAll.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });
                        const networkData = nextProps.targetUserDeleteAll.payload.result.remote_addresses;

                        this.setState({
                            loading: nextProps.targetUserDeleteAll.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            networkData,
                            etag: this.state.etag + 1,
                            rowTargetData: nextProps.targetUserDeleteAll.payload.result,
                            //uuidがないとそもそもpermissionを読みに行けないのでredundant
                            //uuid: "",
                            self_uuid: nextProps.targetUserDeleteAll.payload.result.uuid ? nextProps.targetUserDeleteAll.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.targetUserDeleteAll.payload.result.foreign_uuid ? nextProps.targetUserDeleteAll.payload.result.foreign_uuid : this.props.foreign_uuid
                        })
                    } else {
                        this.setState({
                            loading: nextProps.targetUserDeleteAll.meta.fetch
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.targetUserDeleteAll.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.targetUserDeleteAll.meta.fetch
                })
            }
        }


        if (!nextProps.visible) {
            this.setState({
                groupCheckedKeys: [],
                groupCheckedRows: [],
                userNetworkKeys: [],
                networkData: "",
                self_uuid: null,
                foreign_uuid: null,
                currentActiveKey: "2",
                type: "",
                loading: false,
                confirmLoading: false,
                etag: 0,
            })
        }
    }

    saveMyTarget(value) {
        this.props.createMyTarget(
            {
                target_uuid: this.state.rowTargetData.uuid,
                name: value.name,
                description: value.description
            }
        )
        //my-targetに保存したものとは別のコピー済みのものを利用する
        this.setState({
            after_save : true
        },()=>{
            this.props.copyTarget(this.state.rowTargetData.uuid)
        })
    }


    generatePostData() {
        const target_group_clusters = this.state.groupCheckedKeys;
        const target_remote_addresses = this.state.networkData;

        let object = {
            type: this.state.type,
            target_group_clusters,
            target_remote_addresses
        }
        return object;
    }


    handleOk = () => {
        this.props.handleOk(this.state.rowTargetData, "target", this.props.key);
        /*
        this.setState({
            confirmLoading: true,
        });

        if (!this.state.self_uuid) {
            //自身のuuidが無いので target_uuidのみ新規作成
            this.props.createTarget(this.generatePostData());
        } else if (this.state.self_uuid) {
            //自身のuuidは存在するので上書き
            this.props.updateTarget(this.state.self_uuid, this.generatePostData());
        }
        */
    };

    addUserAllTarget = (data) => {
        this.props.addUserAllTarget(this.state.self_uuid, data)
    };

    addUserCSVTarget = (data) => {
        log.debug(data);
        this.props.addUserCsvTarget(this.state.self_uuid, data)
    };

    handleCancel = () => {
        this.props.handleCancel()
    };

    handleOpenPreview = () => {
        this.setState({
            previewModalIsOpen: true
        })
    };

    //ここから
    setGroup = (keys, data) => {
        this.setState({
            groupCheckedKeys: keys,
            groupCheckedRows: data,
            loading: true
        }, () => {
            log.debug(this.state.self_uuid);
            log.debug(this.state.foreign_uuid);
            if (!this.state.self_uuid) {
                //自身のuuidが無いので target_uuidのみ新規作成
                this.props.createTarget(this.generatePostData());
            } else if (this.state.self_uuid) {
                //自身のuuidは存在するので上書き
                this.props.updateTarget(this.state.self_uuid, this.generatePostData());
            } else {
                //要素も新規、対象選択も新規で構築　（構築後、元画面に対象選択のuuidを渡す）
                this.props.createTarget(this.generatePostData());
            }
        })
    }


    setUsers = (keys, data) => {
        this.setState({
            userCheckedKeys: keys,
            userCheckedRows: data
        }, () => {
            if (!this.state.self_uuid && this.state.foreign_uuid) {
                //自身のuuidが無いので target_uuidのみ新規作成
                this.props.addUserTarget(this.state.self_uuid, {
                    user_uuids: this.state.userCheckedKeys
                });
            } else if (this.state.self_uuid) {
                //自身のuuidは存在するので上書き
                this.props.addUserTarget(this.state.self_uuid, {
                    user_uuids: this.state.userCheckedKeys
                });
            } else {
                //要素も新規、対象選択も新規で構築　（構築後、元画面に対象選択のuuidを渡す）
                this.props.createTarget(this.generatePostData());
            }
        });
    };


    deleteUsers = (userArray) => {
        this.props.deleteUserTarget(this.state.self_uuid, {
            user_uuids: userArray
        });
    };

    deleteUserAll = () => {
        this.props.deleteUserAllTarget(this.state.self_uuid);
    }

    setNetwork = (data) => {
        const old_networkData = this.state.networkData;
        this.setState({
            oldNetworkData: old_networkData,
            networkData: data
        }, () => {
            if (!this.state.self_uuid && this.state.foreign_uuid) {
                //自身のuuidが無いので target_uuidのみ新規作成
                this.props.createTarget(this.generatePostData());
            } else if (this.state.self_uuid) {
                //自身のuuidは存在するので上書き
                this.props.updateTarget(this.state.self_uuid, this.generatePostData());
            } else {
                //要素も新規、対象選択も新規で構築　（構築後、元画面に対象選択のuuidを渡す）
                this.props.createTarget(this.generatePostData());
            }
        });
    };

    onChangeTab = (value) => {
        this.setState({
            currentActiveKey: value,
            etag: this.state.etag + 1
        })
    };

    render() {
        const {visible, confirmLoading} = this.state;
        return (
            <Modal
                forceRender
                className="common-modal select-target-form"
                title={<span><UserOutlined/> 対象者選択</span>}
                visible={visible}
                maskClosable={false}
                onOk={this.handleOk}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back-select-target-form" size="large" onClick={this.handleCancel}>保存せずに閉じる</Button>,
                    <Button key="preview-select-target-form" size="large" onClick={this.handleOpenPreview}>配信対象者を確認</Button>,
                    <Button key="create-select-target-form" type={"primary"} size="large" onClick={()=> {
                        this.handleOk();
                    }}>よく使う対象者へ保存せずに続行</Button>,
                    <Button key="create-save-select-target-form" type={"primary"} size="large" onClick={()=>{
                        this.setState({myTargetEditModalIsOpen: true})
                    }}>よく使う対象者として保存して続行</Button>
                ]}>
                <Tabs
                    className="content-tab"
                    defaultActiveKey="2"
                    activeKey={this.state.currentActiveKey}
                    onChange={this.onChangeTab}
                    animated={false}>
                    <TabPane tab={<span><TeamOutlined/>対象グループ</span>} key="1">
                        <SelectTargetGroupList
                            type="target"
                            visible={this.props.visible}
                            loading={this.state.loading}
                            setObject={this.setGroup}
                            checkedKeys={this.state.groupCheckedKeys}
                            checkedRows={this.state.groupCheckedRows}
                        />
                    </TabPane>
                    <TabPane tab={<span><UserOutlined/>対象ユーザ</span>} key="2">
                        <SelectTargetFromUsers
                            setObject={this.setUsers}
                            type="target"
                            visible={this.props.visible}
                            loading={this.state.loading}
                            self_uuid={this.state.self_uuid}
                            etag={this.state.etag}
                            deleteUsers={this.deleteUsers}
                            addUserAll={this.addUserAllTarget}
                            addUserCSV={this.addUserCSVTarget}
                            deleteUserAll={this.deleteUserAll}
                        />
                    </TabPane>
                    {this.props.type === 3 || this.props.type === 4 || this.props.type === 5 ?
                        <TabPane tab={<span><EnvironmentOutlined/>ネットワーク制限</span>} key="3">
                            <SelectTargetFormIPAddress
                                visible={visible}
                                setObject={this.setNetwork}
                                networkData={this.state.networkData}/>
                        </TabPane>
                        :
                        null
                    }
                </Tabs>

                <SelectTargetPreview
                    visible={this.state.previewModalIsOpen}
                    self_uuid={this.state.self_uuid}
                    type="target"
                    handleOk={() => {
                        this.setState({
                            previewModalIsOpen: false
                        })
                    }}
                    handleCancel={() => {
                        this.setState({
                            previewModalIsOpen: false
                        })
                    }}
                />

                <MyTargetEditModal
                    visible={this.state.myTargetEditModalIsOpen}
                    handleOk={(values)=>{
                        //myTargetに保存
                        this.saveMyTarget(values)
                        //自身のmodalを閉じる
                        this.setState({
                            myTargetEditModalIsOpen: false
                        });
                    }}
                    handleCancel={()=>{this.setState({myTargetEditModalIsOpen: false})}}
                />
            </Modal>
        );
    }

}

SelectTargetForm.propTypes = {
    visible: PropTypes.bool.isRequired
    ,
    handleOk: PropTypes.func.isRequired
    ,
    handleCancel: PropTypes.func.isRequired
    ,
    self_uuid: PropTypes.string
    ,
    foreign_uuid: PropTypes.string
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectTargetForm);