import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import * as Actions from '../../../../redux/actions'
import * as GlobalConfig from '../../../../constants/GlobalConfig'
import {instanceOf} from 'prop-types';
import * as TagNodeGenerator from '../../../../components/utils/TagNodeGenerator'
import moment from 'moment';
import 'moment/locale/ja';
import {createColorSpannedString, stripHtmlTags, extractFirstSurroundingText} from '../../../../components/utils/FullTextSearch';

import {
    CheckCircleOutlined,
    FolderOutlined,
    InboxOutlined, LikeOutlined,
    NotificationOutlined,
    PushpinOutlined,
} from '@ant-design/icons';

import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {Modal, Table, Tabs, Input, Button, Popconfirm, Badge, Tooltip, Col, DatePicker, Tag, Row, Radio} from 'antd';
import log from 'components/utils/Logger';
import {notificationSetReadSelected, notificationSetUnReadSelected} from "../../../../redux/reducers";
import jaJP from "antd/lib/date-picker/locale/ja_JP";

moment.locale('ja');

const Search = Input.Search;
const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const RangePicker = DatePicker.RangePicker;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        paramCategory: state.paramNotificationCategory,
        notificationModuleList: state.notificationModuleList,
        session: state.session,
        notificationSetReadState: state.notificationSetRead,
        notificationSetUnReadSelected: state.notificationSetUnReadSelected,
        notificationSetReadSelected: state.notificationSetReadSelected,
        notificationSetPinSelected: state.notificationSetPinSelected,
        notificationSetUnpinSelected: state.notificationSetUnpinSelected
    }
};


const mapDispatchToProps = dispatch => {
    return {
        getCategory: () => {
            dispatch(Actions.Connection.notification.category.parameter.request());
        },
        searchNotification: (data, currentPageNum) => {
            dispatch(Actions.Connection.notification.moduleList.request(data, currentPageNum));
        },
        notificationSetRead: (categoryUuid) => {
            dispatch(Actions.Connection.notification.setread.request(categoryUuid));
        },
        setMultiRead: (uuidArray) => {
            const object = {uuid: uuidArray};
            dispatch(Actions.Connection.notification.setreadselected.request(object));
        },
        setMultiUnRead: (uuidArray) => {
            const object = {uuid: uuidArray};
            dispatch(Actions.Connection.notification.setunreadselected.request(object));
        },
        setMultiPin: (uuidArray) => {
            const object = {uuid: uuidArray};
            dispatch(Actions.Connection.notification.setpinselected.request(object));
        },
        setMultiUnpin: (uuidArray) => {
            const object = {uuid: uuidArray};
            dispatch(Actions.Connection.notification.setunpinselected.request(object));

        }
    }
};

class ModuleNotificationModalList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalTitle: props.title,
            currentCategory: props.defaultCategory,
            loading: this.props.paramCategory.meta.fetch,
            searchLoading: this.props.notificationModuleList.meta.fetch,
            selectedRowKeys: [],
            selectedUUIDs: [],
            paramCategoryArray: [],
            resultNotificationArray: [],
            pagination: {
                showTotal: (total, range) => `全${total}件中 ${range[0]}-${range[1]} 件`,
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize,
                total: 0,
            },
            name: "",
            allUnreadCount: 0,
            fulltextSearchWord: "",
            searchType: 'fulltext',
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            notificationModuleList,
            paramCategory,
            notificationSetReadState,
            notificationSetUnReadSelected,
            notificationSetReadSelected,
            notificationSetUnpinSelected,
            notificationSetPinSelected
        } = {...this.props};

        if (nextProps.visible !== this.props.visible && nextProps.visible) {
            this.props.getCategory();
            this.search(this.state.pagination, "");
        } else {
            if (nextProps.singleModalIsOpen !== this.props.singleModalIsOpen) {
                this.search(this.state.pagination, this.state.currentCategory);
            }
        }

        if (nextProps.defaultCategory !== this.props.defaultCategory) {
            this.setState({
                currentCategory: nextProps.defaultCategory
            })
        }


        this.setState({
            visible: nextProps.visible,
        });


        // カテゴリ一覧の取得

        if (nextProps.paramCategory !== paramCategory) {
            if (!nextProps.paramCategory.meta.fetch) {
                if (!nextProps.paramCategory.error) {
                    if (nextProps.paramCategory.payload !== null) {
                        if (nextProps.paramCategory.payload.hasOwnProperty("result") && nextProps.paramCategory.payload.result.hasOwnProperty("items")) {
                            this.setState({
                                loading: nextProps.paramCategory.meta.fetch,
                                paramCategoryArray: nextProps.paramCategory.payload.result.items,
                                allUnreadCount: nextProps.paramCategory.payload.result.unread_count,
                            })
                        }
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.paramCategory.meta.fetch
                })
            }
        }

        // お知らせリストの取得
        if (nextProps.notificationModuleList !== notificationModuleList) {
            if (!nextProps.notificationModuleList.meta.fetch) {
                if (!nextProps.notificationModuleList.error) {
                    if (nextProps.notificationModuleList.payload !== null) {
                        this.setState({
                            searchLoading: nextProps.notificationModuleList.meta.fetch,
                            resultNotificationArray: nextProps.notificationModuleList.payload.result.items,
                            pagination: {
                                ...this.state.pagination,
                                total: nextProps.notificationModuleList.payload.result.item_count,
                            },
                            selectedRowKeys: [],
                            fulltextSearchWord: this.props.form.getFieldValue("fulltext")
                        })
                    }
                }
            } else {
                this.setState({
                    searchLoading: nextProps.notificationModuleList.meta.fetch
                })
            }
        }

        // 既読処理
        if (nextProps.notificationSetReadState !== notificationSetReadState) {
            if (!nextProps.notificationSetReadState.meta.fetch) {
                if (!nextProps.notificationSetReadState.error) {
                    if (nextProps.notificationSetReadState.payload !== null) {
                        log.debug(nextProps.notificationSetReadState.payload);
                        if (nextProps.notificationSetReadState.payload.hasOwnProperty("result")) {
                            this.props.getCategory();
                            this.search(undefined, this.state.currentCategory);
                        }
                    }
                }
            }
            this.setState({
                loading: nextProps.notificationSetReadState.meta.fetch
            });
        }

        if (nextProps.notificationSetReadSelected !== notificationSetReadSelected) {
            if (!nextProps.notificationSetReadSelected.meta.fetch) {
                if (!nextProps.notificationSetReadSelected.error) {
                    if (nextProps.notificationSetReadSelected.payload !== null) {
                        if (nextProps.notificationSetUnReadSelected.payload.is_successful) {
                            this.setState({
                                loading: nextProps.notificationSetReadSelected.meta.fetch,
                            })
                            this.props.getCategory();
                            this.search(undefined, this.state.currentCategory);
                        }
                    }
                } else {
                    this.setState({
                        loading: nextProps.notificationSetReadSelected.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.notificationSetReadSelected.meta.fetch
                })
            }
        }

        if (nextProps.notificationSetUnReadSelected !== notificationSetUnReadSelected) {
            if (!nextProps.notificationSetUnReadSelected.meta.fetch) {
                if (!nextProps.notificationSetUnReadSelected.error) {
                    if (nextProps.notificationSetUnReadSelected.payload !== null) {
                        if (nextProps.notificationSetUnReadSelected.payload.is_successful) {
                            this.setState({
                                loading: nextProps.notificationSetUnReadSelected.meta.fetch,
                            })
                            this.props.getCategory();
                            this.search(undefined, this.state.currentCategory);

                        }
                    }
                } else {
                    this.setState({
                        loading: nextProps.notificationSetUnReadSelected.meta.fetch,
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.notificationSetUnReadSelected.meta.fetch
                })
            }
        }

        if (nextProps.notificationSetPinSelected !== notificationSetPinSelected) {
            if (!nextProps.notificationSetPinSelected.meta.fetch) {
                if (!nextProps.notificationSetPinSelected.error) {
                    if (nextProps.notificationSetPinSelected.payload !== null) {
                        if (nextProps.notificationSetPinSelected.payload.is_successful) {
                            this.setState({
                                loading: nextProps.notificationSetPinSelected.meta.fetch,
                            })
                            this.props.getCategory();
                            this.search(undefined, this.state.currentCategory);

                        }
                    }
                } else {
                    this.setState({
                        loading: nextProps.notificationSetPinSelected.meta.fetch,
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.notificationSetPinSelected.meta.fetch
                })
            }
        }

        if (nextProps.notificationSetUnpinSelected !== notificationSetUnpinSelected) {
            if (!nextProps.notificationSetUnpinSelected.meta.fetch) {
                if (!nextProps.notificationSetUnpinSelected.error) {
                    if (nextProps.notificationSetUnpinSelected.payload !== null) {
                        if (nextProps.notificationSetUnpinSelected.payload.is_successful) {
                            this.setState({
                                loading: nextProps.notificationSetUnpinSelected.meta.fetch,
                            })
                            this.props.getCategory();
                            this.search(undefined, this.state.currentCategory);
                        }
                    }
                } else {
                    this.setState({
                        loading: nextProps.notificationSetUnpinSelected.meta.fetch,
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.notificationSetUnpinSelected.meta.fetch
                })
            }
        }


        if (nextProps.session && nextProps.session.hasOwnProperty("payload") && nextProps.session.payload.hasOwnProperty("user")) {
            const name = nextProps.session.payload.user.name;
            this.setState({
                name
            })
        }

    }

    handleCancel = () => {
        this.props.handleCancel();
    };


    onSelectChange = (selectedRowKeys) => {
        this.setState({selectedRowKeys});
    };


    onChangePageSize = (current, pageSize) => {
        const pager = {...this.state.pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    };

    onChangeRadio = (e) => {
        this.setState({
            searchType: e.target.value
        })
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: {
                ...this.state.pagination,
                ...pagination
            }
        }, () => {
            this.search(pagination,this.state.currentCategory);
        })
    };

    search(value, category_uuid) {
        let fieldsValueKeys;
        if(this.state.searchType === 'fulltext') {
            fieldsValueKeys = ['fulltext']
        }
        if(this.state.searchType === 'detail') {
            fieldsValueKeys = ["title", "publish_from", "range_picker_start", "range_picker_end"]
        }
        let fieldsValue = this.props.form.getFieldsValue(fieldsValueKeys);
        let pagination;
        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = {
                ...this.state.pagination,
                ...value
            };
        }

        if (this.state.currentCategory === "PIN") {
            fieldsValue.is_pinned = 1;
        }

        fieldsValue.page_size = pagination.pageSize;
        if (typeof category_uuid !== "undefined" && category_uuid !== "ALL" && category_uuid !== "PIN") {
            fieldsValue.category_uuid = category_uuid;
        }

        if(this.state.searchType === 'fulltext') {
            this.props.searchNotification(fieldsValue, pagination.current);
        }
        if(this.state.searchType === 'detail') {
            const range_picker_start = fieldsValue.range_picker_start;
            const range_picker_end = fieldsValue.range_picker_end;

            if (typeof range_picker_start !== "undefined" && range_picker_start) {
                fieldsValue.publish_start_at = range_picker_start.startOf("day").unix();

            }
            if (typeof range_picker_end !== "undefined" && range_picker_end) {
                fieldsValue.publish_end_at = range_picker_end.endOf('day').unix();
            }
            delete fieldsValue.range_picker_start;
            delete fieldsValue.range_picker_end;


            this.props.searchNotification(fieldsValue, pagination.current);
        }

        this.setState({
            pagination
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.search({current: 1});
        return false;
    };

    handleResetSearchForm = () => {
        this.props.form.resetFields();
        this.search({current: 1});
    };

    onChangeTab = (key) => {
        const category_uuid = (key === "ALL" || key === "PIN") ? "" : key;
        const is_pinned = key === "PIN";
        const pagination = {...this.state.pagination};
        //pageSizeの更新
        pagination.current = 1;
        this.setState({
            currentCategory: key,
            pagination
        }, () => {
            this.search(pagination, category_uuid);
        });
    };

    onSelectChange = (selectedRowKeys, selectedRowInfo) => {
        const selectedUUIDs = selectedRowInfo.map((value) => {
            return value.uuid;
        })
        this.setState({
            selectedRowKeys,
            selectedUUIDs
        });
    };
    setMultiRead = () => {
        this.props.setMultiRead(this.state.selectedUUIDs);
    }

    setMultiUnRead = () => {
        this.props.setMultiUnRead(this.state.selectedUUIDs);
    }

    setMultiUnpin = () => {
        this.props.setMultiUnpin(this.state.selectedUUIDs);
    }

    setMultiPin = () => {
        this.props.setMultiPin(this.state.selectedUUIDs);
    }


    disabledStartDate = (startValue) => {
        const endValue = this.state.endValue;
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf();
    }

    disabledEndDate = (endValue) => {
        const startValue = this.state.startValue;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    }
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({endOpen: true});
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({endOpen: open});
    }


    onChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('startValue', value);
    }

    onEndChange = (value) => {
        this.onChange('endValue', value);
    }


    render() {
        const {visible, selectedRowKeys, fulltextSearchWord} = this.state;

        const columns = [{
            title: 'タイトル',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => {
                const tagNode = TagNodeGenerator.generate(TagNodeGenerator.TYPES_NOTIFICATION_PRIORITY, record.priority);
                const haveThumb = record.images?.length > 0;
                const titleText = createColorSpannedString(text, fulltextSearchWord);
                const content = extractFirstSurroundingText(stripHtmlTags(record.content), fulltextSearchWord);
                const spannedContent = createColorSpannedString(content, fulltextSearchWord, true);

                if (record.is_read === true) {
                    // 既読
                    return (
                        <div style={{cursor: "pointer"}} className={'module-notification-modal-title'}>
                            <div style={{display:"flex", flexDirection: "column", flexGrow: 1}}>
                                <div>{tagNode}<Tag color="geekblue">{record.category.name}</Tag></div>
                                <span style={{margin:'5px 0'}}>{titleText}</span>
                                <span className='spanned-content'>{spannedContent}</span>
                                <div className={"is-good-icon-wrapper"}>
                                    <span className={`is-good-icon ${record.is_reacted_good ? "like": "unlike"}`} onClick={this.props.setLike}>気になる！ {record.good_count}</span>
                                </div>
                            </div>
                            <div className={"module-cell-image-wrapper"}>
                                {haveThumb &&
                                <img src={`${process.env.REACT_APP_FILE_DOMAIN}/file/thumb/${record.images[0].uuid}`}/>
                                }
                            </div>
                        </div>
                    )
                } else {
                    // 未読
                    return (
                        <div style={{cursor: "pointer"}} className={'module-notification-modal-title'}>
                            <div style={{display:"flex", flexDirection: "column", flexGrow: 1}}>
                                <div>{tagNode}<Tag color="geekblue">{record.category.name}</Tag></div>
                                <span style={{margin:'5px 0'}}><strong>{titleText}</strong></span>
                                <span className='spanned-content'><strong>{spannedContent}</strong></span>
                                <div className={"is-good-icon-wrapper"}>
                                    <span className={`is-good-icon ${record.is_reacted_good ? "like": "unlike"}`} onClick={this.props.setLike}>気になる！ {record.good_count}</span>
                                </div>
                            </div>
                                <div className={"module-cell-image-wrapper"}>
                                    {haveThumb &&
                                    <img src={`${process.env.REACT_APP_FILE_DOMAIN}/file/thumb/${record.images[0].uuid}`}/>
                                    }
                                </div>
                        </div>
                    )
                }
            }
        },  {
            title: '配信元',
            dataIndex: 'publish_from',
            key: 'publish_from',
            width: "180px",
            render: (text, record) => {
                return createColorSpannedString(text, this.state.fulltextSearchWord);
            }
        }, {
            title: '公開期間',
            dataIndex: 'range_data',
            key: 'range_data',
            width: "200px",
            render: (text, record) => {
                const publish_start = moment.unix(record.publish_start_at).format("YYYY.MM.DD HH:mm");
                const publish_end = moment.unix(record.publish_end_at).format("YYYY.MM.DD HH:mm");
                return (
                    <div>
                        {publish_start} から<br/>
                        {publish_end} まで
                    </div>
                )
            }
        },

        ];

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true
        };

        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        let availableSearchForm;

        if (this.state.searchType === "fulltext") {
            // 全文検索
            availableSearchForm = (
                <Form className="search-form" onSubmit={this.onSubmit}>
                    <FormItem
                        {...formItemLayout}
                        label="検索キーワード"
                        layout="inline">
                        {getFieldDecorator('fulltext')(
                            <Input placeholder=""/>
                        )}
                    </FormItem>

                    <div className="submit-container">

                        <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                            リセット
                        </Button>

                        <Button type="primary" htmlType="submit" className="search-form-button">
                            検索
                        </Button>

                    </div>
                </Form>
            )
        } else {
            availableSearchForm = (
                // 詳細検索
                <Form className="search-form" name="detail-search-form" onSubmit={this.onSubmit}>
                    <FormItem
                        {...formItemLayout}
                        label="タイトル"
                        layout="inline">
                        {getFieldDecorator('title')(
                            <Input placeholder="タイトルを入力してください"/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="配信元"
                        layout="inline">
                        {getFieldDecorator('publish_from')(
                            <Input placeholder="配信元を入力してください"/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="公開期間"
                        extra="指定した期間に公開中となっているお知らせを対象とします。"
                        >
                        <Row>
                        <Col span={4}>
                        <FormItem >
                                {getFieldDecorator('range_picker_start')(
                                    <DatePicker
                                        locale={jaJP}
                                        format="YYYY/MM/DD"
                                        disabledDate={this.disabledStartDate}
                                        placeholder="開始日時"
                                        onChange={this.onStartChange}
                                        onOpenChange={this.handleStartOpenChange}
                                    />
                                )}
                            </FormItem>
                        </Col>
                            <Col span={4}>
                            <FormItem wrapperCol={{span:24}}>
                                {getFieldDecorator('range_picker_end')(
                                    <DatePicker
                                        locale={jaJP}
                                        format="YYYY/MM/DD"
                                        disabledDate={this.disabledEndDate}
                                        placeholder="終了日時"
                                        onChange={this.onEndChange}
                                        open={this.state.endOpen}
                                        onOpenChange={this.handleEndOpenChange}
                                    />
                                )}
                            </FormItem>
                        </Col></Row>
                    </FormItem>

                    <div className="submit-container">
                        <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                            リセット
                        </Button>
                        <Button type="primary" htmlType="submit" className="search-form-button">
                            検索
                        </Button>
                    </div>
                </Form>
            )
        }

        let currentCategory = this.state.paramCategoryArray.filter((value) => {
            return value.hasOwnProperty(this.state.currentCategory);
        });

        const notificationTabContent =
            <div>
                <h2>{currentCategory.name}</h2>
                <p>{currentCategory.description}</p>
                <RadioGroup
                    defaultValue="fulltext"
                    size="medium"
                    value={this.state.searchType}
                    onChange={this.onChangeRadio}>
                    <RadioButton value="fulltext">全文検索</RadioButton>
                    <RadioButton value="detail">詳細検索</RadioButton>
                </RadioGroup>
                <div className="card general-search">
                    {availableSearchForm}
                </div>

                <div className="table-header-wrapper">
                    <Popconfirm
                        title={<span>すべてのお知らせを既読に変更します</span>}
                        onConfirm={() => {
                            if (this.state.currentCategory !== "ALL" && this.state.currentCategory !== "PIN") {
                                this.props.notificationSetRead(this.state.currentCategory);
                            } else if (this.state.currentCategory === "PIN") {
                                this.props.notificationSetRead("?is_pinned=1");
                            } else {
                                this.props.notificationSetRead();
                            }
                        }}
                        onCancel=""
                        placement="left"
                        okText="既読にする" cancelText="キャンセル">
                        <Button type="primary" onClick={() => {
                        }}>
                            <CheckCircleOutlined/> すべて既読にする
                        </Button>
                    </Popconfirm>
                </div>

                <Table
                    className="general-table"
                    rowKey={"uuid"}
                    rowSelection={rowSelection}
                    locale={{
                        filterTitle: 'フィルタ',
                        filterConfirm: '確定',
                        filterReset: 'リセット',
                        emptyText: '該当するものはありません',
                    }}
                    bordered={true}
                    columns={columns} dataSource={this.state.resultNotificationArray}
                    size="middle"
                    loading={this.state.searchLoading}
                    onChange={this.handleTableChange}
                    pagination={this.state.pagination}
                    onRow={(record, rowIndex) => {
                        const tempRecord = record;
                        tempRecord.fulltextSearchWord = fulltextSearchWord;
                        return {
                            onClick: event => {
                                this.props.handleRowClick(tempRecord)
                            }
                        };
                    }}
                    footer={() => {
                        if (selectedRowKeys.length !== 0) {
                            return (
                                <div>
                                    <span>選択中の{selectedRowKeys.length}件を</span>
                                    <Popconfirm
                                        title={
                                            <span>選択中の{selectedRowKeys.length}件を未読に変更します。</span>
                                        }
                                        onConfirm={() => {
                                            this.setMultiUnRead();
                                        }}
                                        onCancel=""
                                        placement="right"
                                        okText="未読に変更" cancelText="キャンセル">
                                        <Tooltip placement="topLeft" title="未読に変更">
                                            <Button type="primary" size={"small"}
                                                    style={{marginLeft: "10px"}}>未読にする</Button>
                                        </Tooltip>
                                    </Popconfirm>

                                    <Popconfirm
                                        title={
                                            <span>選択中の{selectedRowKeys.length}件を既読に変更します。</span>
                                        }
                                        onConfirm={() => {
                                            this.setMultiRead();
                                        }}
                                        onCancel=""
                                        placement="right"
                                        okText="既読に変更" cancelText="キャンセル">
                                        <Tooltip placement="topLeft" title="既読に変更">
                                            <Button type="primary" size={"small"}
                                                    style={{marginLeft: "10px"}}>既読にする</Button>
                                        </Tooltip>
                                    </Popconfirm>
                                    {this.state.currentCategory === "PIN" ?
                                        <Popconfirm
                                            title={
                                                <span>選択中の{selectedRowKeys.length}件のピン留めを解除します。</span>
                                            }
                                            onConfirm={() => {
                                                this.setMultiUnpin();
                                            }}
                                            onCancel=""
                                            placement="right"
                                            okText="ピン留め解除" cancelText="キャンセル">
                                            <Tooltip placement="topLeft" title="ピン留め解除">
                                                <Button type="primary" size={"small"}
                                                        style={{marginLeft: "10px"}}>ピン留めを解除する</Button>
                                            </Tooltip>
                                        </Popconfirm>
                                        :
                                        null
                                    }
                                </div>
                            )
                        } else {
                            return null;
                        }
                    }}
                    title={() => {
                        if (selectedRowKeys.length !== 0) {
                            return (
                                <div>
                                    <span>選択中の{selectedRowKeys.length}件を</span>
                                    <Popconfirm
                                        title={
                                            <span>選択中の{selectedRowKeys.length}件を未読に変更します。</span>
                                        }
                                        onConfirm={() => {
                                            this.setMultiUnRead();
                                        }}
                                        onCancel=""
                                        placement="right"
                                        okText="未読に変更" cancelText="キャンセル">
                                        <Tooltip placement="topLeft" title="未読に変更">
                                            <Button type="primary" size={"small"}
                                                    style={{marginLeft: "10px"}}>未読にする</Button>
                                        </Tooltip>
                                    </Popconfirm>

                                    <Popconfirm
                                        title={
                                            <span>選択中の{selectedRowKeys.length}件を既読に変更します。</span>
                                        }
                                        onConfirm={() => {
                                            this.setMultiRead();
                                        }}
                                        onCancel=""
                                        placement="right"
                                        okText="既読に変更" cancelText="キャンセル">
                                        <Tooltip placement="topLeft" title="既読に変更">
                                            <Button type="primary" size={"small"}
                                                    style={{marginLeft: "10px"}}>既読にする</Button>
                                        </Tooltip>
                                    </Popconfirm>
                                    {this.state.currentCategory === "PIN" ?
                                        <Popconfirm
                                            title={
                                                <span>選択中の{selectedRowKeys.length}件のピン留めを解除します。</span>
                                            }
                                            onConfirm={() => {
                                                this.setMultiUnpin();
                                            }}
                                            onCancel=""
                                            placement="right"
                                            okText="ピン留め解除" cancelText="キャンセル">
                                            <Tooltip placement="topLeft" title="ピン留め解除">
                                                <Button type="primary" size={"small"}
                                                        style={{marginLeft: "10px"}}>ピン留めを解除する</Button>
                                            </Tooltip>
                                        </Popconfirm>
                                        :
                                        null
                                    }
                                </div>
                            )
                        } else {
                            return null;
                        }
                    }}
                />

            </div>;


        const categoryOptionTabs = this.state.paramCategoryArray.map((value, index) => {
            return (
                <TabPane
                    tab={<span><FolderOutlined/> {value.name} <Badge count={value.unread_count}/></span>}
                    key={value.uuid}>
                    {notificationTabContent}
                </TabPane>
            );
        });


        return (
            <Modal
                zIndex={900}
                className="user-notification-modal"
                style={{top: 20}}
                title={<span><NotificationOutlined/>&nbsp;{this.state.name}さんへのお知らせ</span>}
                visible={visible}
                maskClosable={false}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>
                ]}>
                <div className='wrapper'>
                    <Tabs tabPosition="left"
                          className="content-tab"
                          defaultActiveKey={"ALL"}
                          onChange={this.onChangeTab}
                          animated= {{ inkBar: true, tabPane: false }}
                          activeKey={this.state.currentCategory}
                    >
                        <TabPane
                            tab={<span><InboxOutlined/> すべてのお知らせ <Badge count={this.state.allUnreadCount}/></span>}
                            key="ALL">
                            {notificationTabContent}
                        </TabPane>

                        <TabPane tab={<span><PushpinOutlined/> ピン留めされたお知らせ</span>}
                                 key="PIN">
                            {notificationTabContent}
                        </TabPane>
                        {categoryOptionTabs}
                    </Tabs>
                </div>
            </Modal>
        );
    }
}


ModuleNotificationModalList.propTypes = {
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(ModuleNotificationModalList))
