import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import * as Actions from '../../redux/actions'
import * as GlobalConfig from '../../constants/GlobalConfig'
import log from '../../components/utils/Logger.js'
import moment from 'moment';
import 'moment/locale/ja';
import {
    DeleteOutlined,
    EditOutlined,
    ExportOutlined,
    PlusCircleOutlined,
    QuestionCircleOutlined,
    SolutionOutlined,
} from '@ant-design/icons';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Table,
    Radio,
    Select,
    Input,
    Row,
    Col,
    Button,
    DatePicker,
    Tooltip,
    Spin,
    Popconfirm,
    message,
    Badge, Tag,
} from 'antd';
import NotificationAddModal from './NotificationAddModal';
import NotificationStatusModal from "./NotificationStatusModal"
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import * as TagNodeGenerator from 'components/utils/TagNodeGenerator'
import { createColorSpannedString, extractFirstSurroundingText, stripHtmlTags } from 'components/utils/FullTextSearch';

moment.locale('ja');
const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        paramCategory: state.paramNotificationCategory,
        notification: state.notification,
        notificationUpdate: state.notificationUpdate,
        notificationCreate: state.notificationCreate,
        notificationDelete: state.notificationDelete,
        notificationCopy: state.notificationCopy
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCategory: () => {
            dispatch(Actions.AdminConnection.notification.category.parameter.request());
        },
        searchNotification: (data, currentPageNum) => {
            dispatch(Actions.AdminConnection.notification.search.request(data, currentPageNum));
        },
        createNotification: (data) => {
            dispatch(Actions.AdminConnection.notification.create.request(data));
        },
        updateNotification: (uuid, data) => {
            dispatch(Actions.AdminConnection.notification.update.request(uuid, data));
        },
        deleteNotification: (uuid) => {
            dispatch(Actions.AdminConnection.notification.delete.request(uuid));
        },
        copyNotification: (uuid) => {
            dispatch(Actions.AdminConnection.notification.copy.request(uuid));
        }
    }
};

class NotificationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search_type: "fulltext",
            addModalIsOpen: false,
            editModalIsOpen: false,
            copyModalIsOpen: false,
            statusModalIsOpen: false,
            loading: this.props.paramCategory.meta.fetch,
            searchLoading: this.props.notification.meta.fetch,
            statusTargetUUID: null,
            paramCategoryArray: [],
            resultNotificationArray: [],
            selectedRowKeys: [],
            pagination: {
                showTotal: (total, range) => {

                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize,
                total: 0,
            },

            startValue: null,
            endValue: null,
            endOpen: false,
            fulltextSearchWord: '',
        }
    }


    componentWillMount() {
        this.props.getCategory();
        this.search();
    }

    componentWillReceiveProps(nextProps) {
        const pagination = {...this.state.pagination};
        const {
            paramCategory,
            notification,
            notificationCreate,
            notificationUpdate,
            notificationDelete,
            notificationCopy
        } = {...this.props};

        if (nextProps.paramCategory !== paramCategory) {
            if (!nextProps.paramCategory.meta.fetch) {
                if (!nextProps.paramCategory.error) {
                    if (nextProps.paramCategory.payload !== null) {
                        this.setState({
                            loading: nextProps.paramCategory.meta.fetch,
                            paramCategoryArray: nextProps.paramCategory.payload.result.items,
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.paramCategory.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.paramCategory.meta.fetch
                })
            }
        }

        if (nextProps.notification !== notification) {
            if (!nextProps.notification.meta.fetch) {
                if (!nextProps.notification.error) {
                    if (nextProps.notification.payload !== null) {
                        pagination.total = nextProps.notification.payload.result.item_count;
                        this.setState({
                            searchLoading: nextProps.notification.meta.fetch,
                            resultNotificationArray: nextProps.notification.payload.result.items,
                            fulltextSearchWord: this.props.form.getFieldValue('fulltext'),
                            //通信後
                            pagination
                        })
                    }
                } else {
                    this.setState({
                        searchLoading: nextProps.notification.meta.fetch,
                    })
                }
            } else {
                this.setState({
                    searchLoading: nextProps.notification.meta.fetch
                })
            }
        }

        if (nextProps.notificationCreate !== notificationCreate) {
            if (!nextProps.notificationCreate.meta.fetch) {
                if (!nextProps.notificationCreate.error) {
                    if (nextProps.notificationCreate.payload !== null) {
                        this.setState({
                            addModalIsOpen: false
                        });
                        this.search()
                    }
                } else {
                    this.setState({
                        searchLoading: nextProps.notificationCreate.meta.fetch,
                    })
                }
            }
        }

        if (nextProps.notificationUpdate !== notificationUpdate) {
            if (!nextProps.notificationUpdate.meta.fetch) {
                if (!nextProps.notificationUpdate.error) {
                    if (nextProps.notificationUpdate.payload !== null) {
                        this.setState({
                            editModalIsOpen: false,
                            copyModalIsOpen: false
                        });
                        this.search()
                    }
                } else {
                    this.setState({
                        searchLoading: nextProps.notificationUpdate.meta.fetch,
                    })
                }
            }
        }

        if (nextProps.notificationDelete !== notificationDelete) {
            if (!nextProps.notificationDelete.meta.fetch) {
                if (!nextProps.notificationDelete.error) {
                    if (nextProps.notificationDelete.payload !== null) {
                        this.search()
                    }
                } else {
                    this.setState({
                        loading: nextProps.notificationDelete.meta.fetch
                    })
                }
            }
        }

        if (nextProps.notificationCopy !== notificationCopy) {
            if (!nextProps.notificationCopy.meta.fetch) {
                if (!nextProps.notificationCopy.error) {
                    if (nextProps.notificationCopy.payload !== null) {
                        this.setState({
                            copyNotificationData: nextProps.notificationCopy.payload.result,
                            copyModalIsOpen: true
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.notificationCopy.meta.fetch
                    })
                }
            }
        }
    }

    onChangePageSize = (current, pageSize) => {
        const pager = {...this.state.pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    };

    search(value) {
        let fieldsValueKeys;
        if(this.state.searchType === 'fulltext') {
            fieldsValueKeys = ['fulltext']
        }
        if(this.state.searchType === 'detail') {
            fieldsValueKeys = ['category_uuid', 'title', 'publish_from', 'status_period', 'range_picker_start', 'range_picker_end']
        }
        let fieldsValue = this.props.form.getFieldsValue(fieldsValueKeys);

        let pagination;
        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = {...this.state.pagination,...value};
        }

        fieldsValue.page_size = pagination.pageSize;

        if (this.state.search_type === "fulltext") {
            this.props.searchNotification(fieldsValue, pagination.current);
        }
        if (this.state.search_type === "detail") {
            const range_picker_start = fieldsValue.range_picker_start;
            const range_picker_end = fieldsValue.range_picker_end;
            const publish_from = fieldsValue.publish_from;
            const publish_from_select = fieldsValue.publish_from_select;

            if (typeof publish_from !== 'undefined') {
                //自由入力inputがからじゃない場合に限り、選択部分を無視するよう設定
                delete fieldsValue.publish_from_select;
            } else {
                //自由入力はundefinedのため、selectの内容をむりくり利用
                delete fieldsValue.publish_from_select;
                fieldsValue.publish_from = publish_from_select;
            }


            if (typeof range_picker_start !== "undefined" && range_picker_start) {
                fieldsValue.publish_start_at = range_picker_start.unix();

            }
            if (typeof range_picker_end !== "undefined" && range_picker_end) {
                fieldsValue.publish_end_at = range_picker_end.unix();
            }
            this.props.searchNotification(fieldsValue, pagination.current);
        }

        // 再リクエスト時は選択済アイテムを空にする
        this.setState({
            selectedRowKeys: [],
            pagination
        });

    }

    onChangeRadio = (e) => {
        this.setState({
            search_type: e.target.value
        })
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.search({current: 1});
        return false;
    };

    handleResetSearchForm = () => {
        this.props.form.resetFields();;
        this.search({current: 1});
    };

    handleOpenAddModal = (e) => {
        e.preventDefault();
        this.setState({
            addModalIsOpen: true
        })
    };

    handleOpenEditModal = (value) => {
        this.setState({
            editNotificationData: value,
            editModalIsOpen: true
        })
    };


    disabledStartDate = (startValue) => {
        const endValue = this.state.endValue;
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf();
    }

    disabledEndDate = (endValue) => {
        const startValue = this.state.startValue;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    }
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({endOpen: true});
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({endOpen: open});
    }


    onChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('startValue', value);
    }

    onEndChange = (value) => {
        this.onChange('endValue', value);
    }


    handleOpenCopyModal = (value) => {
        this.props.copyNotification(value.uuid);
    };


    handleOpenStatusModal = (value) => {
        this.setState({
            statusTargetUUID: value.uuid,
            statusModalIsOpen: true
        })
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};

        /*
        if(pager.current !== pagination.current){
            this.setState({
                pagination: pagination,
            });
        }*/
        this.search(pagination);
    };

    onSelectChange = (selectedRowKeys, selectedRows) => {
        let filteredSelectedRows = selectedRows.filter((value, index) => {
            return value.hasOwnProperty("is_editable") && value.is_editable;
        })
        const filteredSelectedRowKeys = filteredSelectedRows.map((value, index) => {
            return value.uuid;
        });
        if (filteredSelectedRowKeys.length !== selectedRowKeys.length) {
            message.error('編集権限がないお知らせは選択できません');
            this.setState({
                selectedRowKeys: filteredSelectedRowKeys
            });
        } else {
            this.setState({
                selectedRowKeys: filteredSelectedRowKeys
            });
        }
    };


    handleDelete = () => {
        this.props.deleteNotification(this.state.selectedRowKeys);
    };

    render() {

        const {getFieldDecorator} = this.props.form;


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };


        const categoryOptions = this.state.paramCategoryArray.map((value, index) => {
            return (
                <Option key={value.uuid} value={value.uuid}>
                    <div className="select-title">{value.name}</div>
                    <div className="select-description">{value.description}</div>
                </Option>
            )
        });

        // 検索フォーム
        let availableSearchForm;
        if (this.state.search_type === "fulltext") {
            // 全文検索
            availableSearchForm = (
                <Form className="search-form" onSubmit={this.onSubmit}>
                    <FormItem
                        {...formItemLayout}
                        label="検索キーワード"
                        layout="inline">
                        {getFieldDecorator('fulltext')(
                            <Input placeholder=""/>
                        )}
                    </FormItem>

                    <div className="submit-container">

                        <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                            リセット
                        </Button>

                        <Button type="primary" htmlType="submit" className="search-form-button">
                            検索
                        </Button>

                    </div>
                </Form>
            )
        } else {
            availableSearchForm = (
                // 詳細検索
                <Form className="search-form" onSubmit={this.onSubmit}>
                    <FormItem
                        {...formItemLayout}
                        label="カテゴリ"
                    >
                        {getFieldDecorator('category_uuid')(
                            <Select placeholder="カテゴリを選択してください" allowClear={true}>
                                {categoryOptions}
                            </Select>
                        )}
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label="タイトル"
                        layout="inline">
                        {getFieldDecorator('title')(
                            <Input placeholder="タイトルを入力してください"/>
                        )}
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label="配信元">
                        {getFieldDecorator('publish_from')(
                            <Input placeholder="配信元を入力してください"/>
                        )}
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label="状態"
                    >
                        {getFieldDecorator('status_period')(
                            <Select placeholder="状態を選択してください" allowClear={true}>
                                <Option value="public">公開中</Option>
                                <Option value="public_before">公開前</Option>
                                <Option value="public_after">公開終了</Option>
                                <Option value="disable">無効</Option>
                            </Select>
                        )}
                    </FormItem>


                    <FormItem
                        {...formItemLayout}
                        label="作成者"
                        layout="inline">
                        {getFieldDecorator('created_by')(
                            <Input placeholder="作成者を入力してください"/>
                        )}
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label="公開期間"
                        extra="指定した期間に公開中となっているお知らせを対象とします。">
                        {getFieldDecorator('range_picker_start')(
                            <DatePicker
                                locale={jaJP}
                                showTime={{format: 'HH:mm'}}
                                format="YYYY/MM/DD HH:mm"
                                disabledDate={this.disabledStartDate}
                                value={this.state.startValue}
                                placeholder="開始日時"
                                onChange={this.onStartChange}
                                onOpenChange={this.handleStartOpenChange}
                            />
                        )}

                        {getFieldDecorator('range_picker_end')(
                            <DatePicker
                                locale={jaJP}
                                showTime={{format: 'HH:mm'}}
                                format="YYYY/MM/DD HH:mm"
                                disabledDate={this.disabledEndDate}
                                value={this.state.endValue}
                                placeholder="終了日時"
                                onChange={this.onEndChange}
                                open={this.state.endOpen}
                                onOpenChange={this.handleEndOpenChange}
                            />
                        )}
                    </FormItem>

                    <div className="submit-container">
                        <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                            リセット
                        </Button>
                        <Button type="primary" htmlType="submit" className="search-form-button">
                            検索
                        </Button>
                    </div>
                </Form>
            )
        }
        let {sortedInfo, filteredInfo, fulltextSearchWord} = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        const columns = [{
            title: 'タイトル',
            dataIndex: 'title',
            key: 'title',
            width: "300px",
            render: (text, record) => {
                const contentString = stripHtmlTags(record.content);
                return <div>
                    {TagNodeGenerator.generate(TagNodeGenerator.TYPES_NOTIFICATION_PRIORITY, record.priority)}{createColorSpannedString(text, fulltextSearchWord)}
                    <span className='spanned-notification-content'>{createColorSpannedString(extractFirstSurroundingText(contentString, fulltextSearchWord), fulltextSearchWord, true)}</span>
                </div>;
            }
        }, {
            title: 'カテゴリ',
            dataIndex: 'category',
            key: 'category',
            width: "150px",
            render: (text) => {
                return <span>{text.name}</span>
            }
        }, {
            title: '配信元',
            dataIndex: 'publish_from',
            key: 'publish_from',
            width: "200px",
            render: (text) => {return createColorSpannedString(text, fulltextSearchWord)}
        }, {
            title: '公開期間',
            dataIndex: 'range_data',
            key: 'range_data',
            width: "200px",
            render: (text, record) => {
                const publish_start = moment.unix(record.publish_start_at).format("YYYY/MM/DD HH:mm");
                const publish_end = moment.unix(record.publish_end_at).format("YYYY/MM/DD HH:mm");
                const isIndefinitePeriod = record.publish_end_at === 2147483647;
                return (
                    <div>
                        {publish_start} から<br/>
                        {isIndefinitePeriod ? `無期限` : `${publish_end} まで`}
                    </div>
                )
            }
        }, {
            title: '状態',
            dataIndex: 'status_period',
            key: 'status_period',
            width: "150px",
            style: "tag",
            render: (status_period, record) => {
                switch (status_period) {
                    case ("disable"): {
                        return <Badge status="warning" text="無効"/>;
                    }
                    case ("public"): {
                        return <Badge status="processing" text="公開中"/>;
                    }
                    case ("public_before"): {
                        return <Badge status="success" text="公開前"/>;
                    }
                    case ("public_after"): {
                        return <Badge status="default" text="公開終了"/>;
                    }
                    default : {
                        return <Badge status="error" text="不明"/>;
                    }

                }
            }
        }, {
            title: <span>プッシュ配信&nbsp;&nbsp;
                <Tooltip title={
                    <span>
                            配信する：プッシュ配信が可能なユーザへプッシュ送信します。<br/>
                            配信しない：プッシュ配信を実施しない設定になっています。
                        </span>
                }>
                    <QuestionCircleOutlined/>
                    </Tooltip>
                    </span>,
            dataIndex: 'is_send_notification',
            width: "150px",
            key: 'is_send_notification',
            render: (text, record) => {
                if (record.is_send_notification) {
                    return <Badge status="success" text="配信する"/>;
                }
                return <Badge status="default" text="配信しない"/>;
            }
        }, {
            title: "作成者/更新者",
            dataIndex: 'author',
            key: 'author',
            width: "150px",
            render: (text, record) => {
                const created_by_name = record.created_by ? record.created_by.name : "作成者なし";
                const updated_by_name = record.updated_by ? record.updated_by.name : "更新者なし";
                return (
                    <span>{created_by_name}<br/>{updated_by_name}</span>
                );
            }
        }, {
            title: "作成時間/更新時間",
            dataIndex: "updated_at",
            key: "update_at",
            width: "180px",
            render: (text, record) => {
                const update_at = text ? moment.unix(text).format("YYYY/MM/DD HH:mm") : "更新なし";
                const created_at = moment.unix(record.created_at).format("YYYY/MM/DD HH:mm");
                return (
                    <div>
                        {created_at}<br/>
                        {update_at}
                    </div>
                )
            }
        }, {
            title: "",
            width: "160px",
            key: 'action',
            fixed: 'right',
            render: (text, record) => {

                return (
                    <Row type="flex" justify="space-around" align="middle">
                        <Col>
                            <Tooltip placement="topLeft" title="編集">
                                <Button
                                    type="default" shape="circle" icon={<EditOutlined/>} disabled={!record.is_editable}
                                    onClick={() => this.handleOpenEditModal(record)}/>
                            </Tooltip>
                        </Col>
                        <Col>
                            <Tooltip placement="topLeft" title="公開対象者と配信状況を確認">
                                <Button
                                    type="default" shape="circle" icon={<SolutionOutlined/>}
                                    disabled={!record.is_editable}
                                    onClick={() => this.handleOpenStatusModal(record)}/>
                            </Tooltip>
                        </Col>
                        <Col>
                            <Tooltip placement="topLeft" title="コピーして新規作成">
                                <Button
                                    type="default" shape="circle" icon={<ExportOutlined/>}
                                    disabled={!record.is_editable}
                                    onClick={() => this.handleOpenCopyModal(record)}/>
                            </Tooltip>
                        </Col>
                        <Col>
                            {record.is_editable ?
                                <Popconfirm
                                    title={<span>お知らせを削除すると配信は停止されます。<br/>
                                    <strong>削除すると復元することはできません。</strong></span>}
                                    onConfirm={() => {
                                        this.props.deleteNotification(record.uuid)
                                    }}
                                    onCancel=""
                                    placement="left"
                                    okText="削除" cancelText="キャンセル">
                                    <Tooltip placement="topLeft" title="削除">
                                        <Button type="default" shape="circle" icon={<DeleteOutlined/>}/>
                                    </Tooltip>
                                </Popconfirm> :
                                <Tooltip placement="topLeft" title="削除">
                                    <Button type="default" shape="circle" icon={<DeleteOutlined/>} disabled={true}/>
                                </Tooltip>
                            }

                        </Col>
                    </Row>
                );
            },
        }

        ];

        const {selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true
        }
        log.debug(selectedRowKeys);
        return (
            <div id="notification-list-container" className="tab-container" alt="お知らせ一覧">
                <Spin spinning={this.state.loading} delay="500">
                    <div className="card general-search">

                        <RadioGroup
                            defaultValue="fulltext"
                            size="medium"
                            value={this.state.search_type}
                            onChange={this.onChangeRadio}>
                            <RadioButton value="fulltext">全文検索</RadioButton>
                            <RadioButton value="detail">詳細検索</RadioButton>
                        </RadioGroup>
                        {availableSearchForm}

                    </div>

                    <div className="table-header-wrapper">
                        <Button type="primary" onClick={this.handleOpenAddModal} icon={<PlusCircleOutlined/>}>
                            新規作成
                        </Button>
                    </div>
                </Spin>

                <Table
                    rowKey="uuid"
                    className="general-table"
                    rowSelection={rowSelection}
                    locale={{
                        filterTitle: 'フィルタ',
                        filterConfirm: '確定',
                        filterReset: 'リセット',
                        emptyText: '該当するものはありません',
                    }}
                    bordered={true}
                    columns={columns} dataSource={this.state.resultNotificationArray}
                    size="middle"
                    loading={this.state.searchLoading}
                    onChange={this.handleTableChange}
                    pagination={this.state.pagination}
                    scroll={{x: 1630}}
                    footer={() => {
                        if (selectedRowKeys.length !== 0) {
                            return (
                                <div>
                                    <Popconfirm
                                        title={
                                            <span>お知らせを削除すると配信は停止されます。<br/>
                                                <strong>削除すると復元することはできません。</strong>
                                            </span>
                                        }
                                        onConfirm={() => {
                                            this.handleDelete();
                                        }}
                                        onCancel=""
                                        placement="left"
                                        okText="削除" cancelText="キャンセル">
                                        <Button type="primary">選択中の{selectedRowKeys.length}件を削除</Button>
                                    </Popconfirm>
                                </div>
                            )
                        } else {
                            return null;
                        }
                    }}
                    title={() => {
                        if (selectedRowKeys.length !== 0) {
                            return (
                                <div>
                                    <Popconfirm
                                        title={
                                            <span>お知らせを削除すると配信は停止されます。<br/>
                                                <strong>削除すると復元することはできません。</strong>
                                            </span>
                                        }
                                        onConfirm={() => {
                                            this.handleDelete();
                                        }}
                                        onCancel=""
                                        placement="left"
                                        okText="削除" cancelText="キャンセル">
                                        <Button type="primary">選択中の{selectedRowKeys.length}件を削除</Button>
                                    </Popconfirm>
                                </div>
                            )
                        } else {
                            return null;
                        }
                    }}
                />

                <NotificationAddModal
                    isEditMode={false}
                    visible={this.state.addModalIsOpen}
                    paramCategoryArray={this.state.paramCategoryArray}

                    handleCancel={() => {
                        this.setState({
                            addModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue, data) => {
                        this.props.createNotification(fieldValue);
                    }}/>

                <NotificationAddModal
                    isEditMode={true}
                    visible={this.state.editModalIsOpen}
                    paramCategoryArray={this.state.paramCategoryArray}
                    handleCancel={() => {
                        this.setState({
                            editModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue, data) => {
                        this.props.updateNotification(data.uuid, fieldValue);
                    }}
                    data={this.state.editNotificationData}
                />

                <NotificationAddModal
                    isEditMode={true}
                    isCopy={true}
                    visible={this.state.copyModalIsOpen}
                    handleCancel={(uuid) => {
                        this.props.deleteNotification(uuid);
                        this.setState({
                            copyModalIsOpen: false
                        })
                    }}
                    paramCategoryArray={this.state.paramCategoryArray}
                    handleOk={(fieldValue, data) => {
                        this.props.updateNotification(data.uuid, fieldValue);
                    }}
                    data={this.state.copyNotificationData}
                />

                <NotificationStatusModal
                    visible={this.state.statusModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            statusModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue, data) => {
                        this.setState({
                            statusModalIsOpen: false
                        })
                    }}
                    uuid={this.state.statusTargetUUID}
                />
            </div>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(NotificationList))
