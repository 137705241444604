/**
 * Created by sibaservice on 2017/05/15.
 */
import {createActions, createAction, handleActions, combineActions} from 'redux-actions'
import * as url from 'constants/api'
import log from 'components/utils/Logger';

export function apiPayloadCreator(method, data) {
    return {
        method: method,
        data: data
    }
}


export const statusEnum = {
    REQUEST: "REQUEST",
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    ERROR: "ERROR"
};

//旧型を新型に変更
export const http = createActions({
    CONNECTION: {
        AUTH: {
            LOGIN: [
                (data) => ({
                    method: "post",
                    api: url.API_LOGIN,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            LOGIN_SSO: [
                () => ({
                    method: "get",
                    api: url.API_SSO_LOGIN
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            CHECK_LOGGED_IN: [
                () => ({
                    method: "get",
                    api: url.API_CHECK_LOGGED_IN,
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            AUTH_PROFILE: [
                (params, page) => {
                    const pageNum = page === undefined ? "" : page;
                    return ({method: "get", params, api: `${url.API_ADMIN_AUTH_PROFILE}/${pageNum}`})
                },
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        },
        TARGET: {
            MY_TARGET : {
                SEARCH:[
                    (data, page) => {
                        const pageNum = page === undefined ? "" : page;
                        return ({method: "get", data, api: `${url.API_ADMIN_MY_TARGET}/${pageNum}`})
                    },
                    () => ({fetch: true, status: statusEnum.REQUEST})
                ],
                VIEW: [
                    (uuid) => ({method: "get", api: url.API_ADMIN_MY_TARGET+ "/view/" + uuid}),
                    () => ({fetch: true, status: statusEnum.REQUEST})
                ],
                //追加
                CREATE: [
                    (data) => ({method: "put", api: url.API_ADMIN_MY_TARGET + "/create", data}),
                    () => ({fetch: true, status: statusEnum.REQUEST})
                ],
                //編集
                UPDATE: [
                    (uuid, data) => ({method: "post", api: url.API_ADMIN_MY_TARGET + "/update/" + uuid, data}),
                    () => ({fetch: true, status: statusEnum.REQUEST})
                ],
                //削除
                DELETE: [
                    (uuid) => ({method: "delete", api: url.API_ADMIN_MY_TARGET + "/delete/" + uuid}),
                    () => ({fetch: true, status: statusEnum.REQUEST})
                ]
            }
        },
        CURRICULUM: {
            ALL: [
                (data, page) => {
                    const pageNum = page === undefined ? "" : page;
                    return ({method: "get", data, api: `${url.API_SCHEDULE_ALL}/${pageNum}`})
                },
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            CANCEL: [
                (data, page) => {
                    const pageNum = page === undefined ? "" : page;
                    return ({method: "get", data, api: `${url.API_SCHEDULE_CANCEL}/${pageNum}`})
                },
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            EXTRA: [
                (data, page) => {
                    const pageNum = page === undefined ? "" : page;
                    return ({method: "get", data, api: `${url.API_SCHEDULE_EXTRA}/${pageNum}`})
                },
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            CHANGE: [
                (data, page) => {
                    const pageNum = page === undefined ? "" : page;
                    return ({method: "get", data, api: `${url.API_SCHEDULE_CHANGE}/${pageNum}`})
                },
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        },
        NOTIFICATION : {
            LIKE : [
                (uuid) => ({method: "get", api: url.API_NOTIFICATION + "/add-good/" + uuid}),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            UNLIKE: [
                (uuid) => ({method: "get", api: url.API_NOTIFICATION+ "/remove-good/" + uuid}),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        }
    }
});


export const data = createActions({
    SESSION: (is_authenticated) => ({
        is_checked: true,
        is_authenticated,
        is_required_session: process.env.REACT_APP_HAVE_SESSION === "true"
    }),
    USER: (data) => data,
    TENANT: (data) => data,
    FAILURE: [
        (data) => data,
        (data,bool) => ({isShow: bool})
    ],
    SUCCESS: [
        (data) => data,
        (data,bool) => ({isShow: bool})
    ]
});

//下記　旧仕様
export const Connection = createActions({
    CHECK: {
        REQUEST: [
            () => ({method: "get", api: url.API_CHECK_LOGGED_IN}),
            () => ({fetch: true})
        ],
        RECEIVE: undefined,
    },
    CHECK_FIRST: {
        //内容は同様だがActionとしては別 ログイン画面時CHECK
        REQUEST: [
            () => ({method: "get", api: url.API_CHECK_LOGGED_IN}),
            () => ({fetch: true})
        ],
        RECEIVE: undefined
    },
    LOGIN: {
        REQUEST: [
            (method, data) => ({method, data, api: url.API_LOGIN}),
            () => ({fetch: true})
        ],
        RECEIVE: undefined
    },
    LOGOUT: {
        REQUEST: [
            () => ({method: "get", api: url.API_LOGOUT}),
            () => ({fetch: true})
        ],
        RECEIVE: undefined
    },
    //メニュー（ユーザ用メニューおよびモジュールの取得）
    MENU: {
        SEARCH: {
            REQUEST: [
                () => ({method: "get", api: url.API_MENU}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        }
    },
    //モジュール（ユーザ用メニューおよびモジュールの取得）
    MODULES: {
        //検索
        REQUEST: [
            (params, page) => {
                return ({method: "get", params, api: `${url.API_MODULES}`})
            },
            () => ({fetch: true})
        ],
        RECEIVE: undefined,
    },
    //お知らせ関連
    NOTIFICATION: {
        //ゲストお知らせ
        GUEST: {
            REQUEST: [
                (params) => {
                    return ({method: "get", params, api: `${url.API_NOTIFICATION}-guest`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //個別お知らせ参照（既読処理を含む）
        VIEW: {
            REQUEST: [
                (uuid) => ({method: "get", api: url.API_NOTIFICATION + "/view/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
            RESET: undefined
        },
        //検索 　お知らせ検索
        SEARCH: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "1" : page;
                    return ({method: "get", params, api: `${url.API_NOTIFICATION}/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        //モジュール単体読込用
        MODULE: {
            REQUEST: [
                (params, page, moduleUUID) => {
                    const pageNum = page === undefined ? "1" : page;
                    return ({method: "get", params, api: `${url.API_NOTIFICATION}/${pageNum}`})
                },
                (params, page, moduleUUID) => {
                    const module_id = moduleUUID === undefined ? null : moduleUUID;
                    return ({fetch: true, module_id})
                }
            ],
            RECEIVE: undefined,
        },
        //モジュールリスト読込用
        MODULE_LIST: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "1" : page;
                    return ({method: "get", params, api: `${url.API_NOTIFICATION}/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        //全てまたはカテゴリ内全てを既読にする
        SETREAD: {
            REQUEST: [
                (requestUuid) => {
                    const categoryUuid = requestUuid === undefined ? "" : requestUuid;
                    return ({method: "get", api: `${url.API_NOTIFICATION}/set-read-all/${categoryUuid}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        SETREADSELECTED: {
            REQUEST: [
                (data) => {
                    return ({method: "put", data, api: `${url.API_NOTIFICATION}/set-read-selected/`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        SETUNREADSELECTED: {
            REQUEST: [
                (data) => {
                    return ({method: "put", data, api: `${url.API_NOTIFICATION}/set-unread-selected/`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        SETPINSELECTED: {
            REQUEST: [
                (data) => {
                    return ({method: "put", data, api: `${url.API_NOTIFICATION}/set-pin-selected/`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        SETUNPINSELECTED: {
            REQUEST: [
                (data) => {
                    return ({method: "put", data, api: `${url.API_NOTIFICATION}/unset-pin-selected/`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        CATEGORY: {
            //お知らせカテゴリ　(検索用パラメータ取得用一覧)
            PARAMETER: {
                REQUEST: [
                    () => ({method: "get", api: url.API_NOTIFICATION_CATEGORY}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,
            },
            //検索 　お知らせカテゴリ検索
            SEARCH: {
                REQUEST: [
                    (params, page) => {
                        const pageNum = typeof page === "undefined" ? "1" : page;
                        //FIXME pagenumへの対応
//                        return ({method: "get", params, api: `${url.API_NOTIFICATION_CATEGORY}/${pageNum}`})
                        return ({method: "get", params, api: url.API_NOTIFICATION_CATEGORY})
                    },
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
        }
    },
    //アンケート関連
    QUESTIONNAIRE: {
        //検索 　お知らせカテゴリ検索
        SEARCH: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "1" : page;
                    return ({method: "get", params, api: `${url.API_QUESTIONNAIRE}/${pageNum}`})
                },
                (params, page, moduleUUID) => {
                    const module_id = moduleUUID === undefined ? null : moduleUUID;
                    return ({fetch: true, module_id})
                }
            ],
            RECEIVE: undefined
        },
        //検索SHOWMORE moduleの追加検索の為の別store格納
        SEARCH_MORE: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "1" : page;
                    return ({method: "get", params, api: `${url.API_QUESTIONNAIRE}/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //個別お知らせ参照（既読処理を含む）
        VIEW: {
            REQUEST: [
                (uuid) => ({method: "get", api: `${url.API_QUESTIONNAIRE}/view/` + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        ANSWER: {
            SEARCH: {
                REQUEST: [
                    (uuid, data) => ({method: "put", api: url.API_QUESTIONNAIRE + "/answer/" + uuid, data}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,
            },
            RESULT: {
                REQUEST: [
                    (uuid) => ({method: "get", api: url.API_QUESTIONNAIRE + "/answer-result/" + uuid}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,

            },
            //フリーフォームリスト
            FREEFORM: {
                REQUEST: [
                    (uuid, page) => {
                        const pageNum = page === undefined ? "1" : page;
                        return ({method: "get", api: url.API_ANSWER + "/text-result/" + uuid + "/" + pageNum})
                    },
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,
            },
            //添付ファイルリスト
            ATTACHMENT: {
                REQUEST: [
                    (uuid, page) => {
                        const pageNum = page === undefined ? "1" : page;
                        return ({method: "get", api: url.API_ANSWER + "/file-result/" + uuid + "/" + pageNum})
                    },
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,
            }
        },
        CATEGORY: {
            //パラメータ
            PARAMETER: {
                REQUEST: [
                    () => ({method: "get", api: url.API_QUESTIONNAIRE_CATEGORY}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,
            },
            //検索 　お知らせカテゴリ検索
            SEARCH: {
                REQUEST: [
                    (params) => ({method: "get", api: url.API_QUESTIONNAIRE_CATEGORY, params}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
        },
    },
    //学年暦関連
    CALENDAR: {
        SEARCH: {
            REQUEST: [
                (params) => {
                    return ({method: "get", params, api: `${url.API_CALENDAR}/`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        CATEGORY: {
            //検索
            SEARCH: {
                REQUEST: [
                    (params, page) => {
                        const pageNum = page === undefined ? "" : page;
                        return ({method: "get", params, api: `${url.API_CALENDAR_CATEGORY}/${pageNum}`})
                    },
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
        }
    },
    //画像アップロード
    IMAGES: {
        REQUEST: [
            (filename, contentType, data) => ({
                method: "put",
                api: url.API_FILE_POST + `/${filename}`,
                data,
                contentType: contentType
            }),
            () => ({fetch: true})
        ],
        RECEIVE: undefined
    },
    THUMBNAIL: {
        REQUEST: [
            (filename, contentType, data) =>({
                method: 'put',
                api: url.API_THUMB_POST + `/${filename}`,
                data,
                contentType: contentType
            }),
            () => ({fetch:true})
        ],
        RECEIVE: undefined
    },
    FILESHARE: {
        //検索
        CHECK_AGREEMENT: {
            REQUEST: [
                () => {
                    return ({method: "get", api: url.API_FILE_SHARE_AGREEMENT})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        SEARCH: {
            REQUEST: [
                (params, page, uuid, module_id) => {
                    const pageNum = page === undefined ? "1" : page;
                    const generateUuid = typeof uuid === "undefined" ? "" : uuid;
                    return ({method: "get", params, api: `${url.API_FILE_SHARE}/${pageNum}/${generateUuid}`})
                },
                (params, page, uuid, module_id) => ({fetch: true, module_id})
            ],
            RECEIVE: undefined,
        },
        SEE_MORE: {
            REQUEST: [
                (params, page, uuid) => {
                    const pageNum = page === undefined ? "1" : page;
                    const generateUuid = typeof uuid === "undefined" ? "" : uuid;
                    return ({method: "get", params, api: `${url.API_FILE_SHARE}/${pageNum}/${generateUuid}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        RECURSIVE: {
            REQUEST: [
                (params, page, uuid) => {
                    const pageNum = page === undefined ? "1" : page;
                    const generateUuid = typeof uuid === "undefined" ? "" : uuid;
                    return ({
                        method: "get",
                        params,
                        api: `${url.API_FILE_SHARE_RECURSIVE}/${pageNum}/${generateUuid}`
                    })
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        //ユーザ側ファイルデリート
        DELETE: {
            REQUEST: [
                (uuid) => ({method: "delete", api: `${url.API_FILE_SHARE_FILE}/delete/${uuid}`}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        DOWNLOAD: {
            REQUEST: [
                (data) => ({method: "post", api: `${url.API_FILE_SHARE}/prepare-multiple-download/`, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        FILE: {
            CREATE: {
                REQUEST: [
                    (data) => ({method: "put", api: `${url.API_FILE_SHARE_FILE}/create/`, data}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //編集
            UPDATE: {
                REQUEST: [
                    (uuid, data) => ({method: "post", api: `${url.API_FILE_SHARE_FILE}/update/${uuid}`, data}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
        },
        SHARED_SETTING: {
            REQUEST: [
                () => ({method: "get", api: `${url.API_FILE_SHARE_SETTING}/view`}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        SHARED_SETTING_AGREEMENT: {
            REQUEST: [
                (data) => {
                    return ({method: "put", data, api: url.API_FILE_SHARE_SETTING_AGREEMENT})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        }
    },
    //グループ
    GROUP: {
        SEARCH: {
            //ユーザ検索
            REQUEST: [
                () => {
                    return ({method: "get", api: `${url.API_GROUP}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        }
    },
    //ログ
    LOG: {
        //ログ検索（管理者）
        SEARCH: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "" : page;
                    return ({method: "get", params, api: `${url.API_ACTION_LOG}/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        }
    },
    //レイアウト
    LAYOUT: {
        LOAD: {
            REQUEST: [
                (uuid) => ({method: "get", api: url.API_LAYOUT + "/view/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        SAVE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_LAYOUT + "/save/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        }
    },
    LICENSE: {
        REQUEST: [
            () => ({method: "LICENSE", api: url.API_LICENSE}),
            () => ({fetch: true})
        ],
        RECEIVE: undefined
    }
});


export const AdminConnection = createActions({
    LOGIN_MESSAGE_SETTING: {
        VIEW: {
            REQUEST: [
                () => ({method: "get", api: url.API_ADMIN_LOGIN_MESSAGE_SETTING_VIEW}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        SAVE: {
            REQUEST: [
                (data) => ({method: "post", api: url.API_ADMIN_LOGIN_MESSAGE_SETTING_SAVE, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        }
    },
    //メニュー（ユーザ用メニューおよびモジュールの取得）
    MENU: {
        SEARCH: {
            REQUEST: [
                (params) => ({method: "get", api: url.API_ADMIN_MENU, params}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        CREATE: {
            REQUEST: [
                (data) => ({method: "put", api: url.API_ADMIN_MENU + "/create", data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //編集
        UPDATE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_MENU + "/update/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //削除
        DELETE: {
            REQUEST: [
                (uuid) => ({method: "delete", api: url.API_ADMIN_MENU + "/delete/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        SP: {
            SEARCH: {
                REQUEST: [
                    (params) => ({method: "get", api: url.API_ADMIN_MENU_SP, params}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,
            },
            CREATE: {
                REQUEST: [
                    (data) => ({method: "put", api: url.API_ADMIN_MENU_SP + "/create", data}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //編集
            UPDATE: {
                REQUEST: [
                    (uuid, data) => ({method: "post", api: url.API_ADMIN_MENU_SP + "/update/" + uuid, data}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //削除
            DELETE: {
                REQUEST: [
                    (uuid) => ({method: "delete", api: url.API_ADMIN_MENU_SP + "/delete/" + uuid}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
        }
    },
    //モジュール（ユーザ用メニューおよびモジュールの取得）
    MODULES: {
        SEARCH: {
            //検索
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "1" : page;
                    return ({method: "get", params, api: `${url.API_ADMIN_MODULES}/${pageNum}`})

                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        //作成
        CREATE: {
            REQUEST: [
                (data) => ({method: "put", api: url.API_ADMIN_MODULES + "/create", data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //編集
        UPDATE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_MODULES + "/update/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //削除
        DELETE: {
            REQUEST: [
                (uuid) => ({method: "delete", api: url.API_ADMIN_MODULES + "/delete/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        }
    },
    //お知らせ関連
    NOTIFICATION: {
        GUEST: {
            REQUEST: [
                (params) => {
                    return ({method: "get", params, api: `${url.API_ADMIN_NOTIFICATION}-guest`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        VIEW: {
            REQUEST: [
                (uuid) => ({method: "get", api: url.API_ADMIN_NOTIFICATION + "/view/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //検索 　お知らせ検索
        SEARCH: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "1" : page;
                    return ({method: "get", params, api: `${url.API_ADMIN_NOTIFICATION}/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //追加
        CREATE: {
            REQUEST: [
                (data) => ({method: "put", api: url.API_ADMIN_NOTIFICATION + "/create", data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //編集
        UPDATE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_NOTIFICATION + "/update/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //削除
        DELETE: {
            REQUEST: [
                (uuid) => {
                    if (Array.isArray(uuid)) {
                        if (uuid.length > 1) {
                            let uuid_array = "";
                            uuid.forEach((value, index) => {
                                uuid_array += value;
                                if (uuid.length > index + 1) {
                                    uuid_array += ",";
                                }
                            });
                            log.debug(uuid_array);
                            return {
                                method: "delete",
                                api: url.API_ADMIN_NOTIFICATION + "/multiple-delete/" + uuid_array
                            }
                        } else if (uuid.length === 1) {
                            return {method: "delete", api: url.API_ADMIN_NOTIFICATION + "/delete/" + uuid[0]}
                        }
                    } else {
                        return {method: "delete", api: url.API_ADMIN_NOTIFICATION + "/delete/" + uuid}
                    }
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //コピー//管理者用
        COPY: {
            REQUEST: [
                (uuid) => ({method: "get", api: url.API_ADMIN_NOTIFICATION + "/copy/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        // 対象者
        TARGET: {
            REQUEST: [
                (uuid, params, page) => {
                    const pageNum = page === undefined ? "1" : page;
                    return ({
                        method: "get",
                        api: url.API_ADMIN_NOTIFICATION + "/target-users/" + uuid + "/" + pageNum,
                        params
                    })

                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        CATEGORY: {
            //お知らせカテゴリ　(検索用パラメータ取得用一覧)
            PARAMETER: {
                REQUEST: [
                    () => ({method: "get", api: url.API_ADMIN_NOTIFICATION_CATEGORY}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,
            },
            //検索 　お知らせカテゴリ検索
            SEARCH: {
                REQUEST: [
                    (params, page) => {
                        const pageNum = typeof page === "undefined" ? "1" : page;
                        //FIXME pagenumへの対応
//                        return ({method: "get", params, api: `${url.API_NOTIFICATION_CATEGORY}/${pageNum}`})
                        return ({method: "get", params, api: url.API_ADMIN_NOTIFICATION_CATEGORY})
                    },
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //追加
            CREATE: {
                REQUEST: [
                    (data) => ({method: "put", api: url.API_ADMIN_NOTIFICATION_CATEGORY + "/create", data}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //編集
            UPDATE: {
                REQUEST: [
                    (uuid, data) => ({
                        method: "post",
                        api: url.API_ADMIN_NOTIFICATION_CATEGORY + "/update/" + uuid,
                        data
                    }),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //削除
            DELETE: {
                REQUEST: [
                    (uuid) => ({method: "delete", api: url.API_ADMIN_NOTIFICATION_CATEGORY + "/delete/" + uuid}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            }
        }
    },
    //アンケート関連
    QUESTIONNAIRE: {
        //検索 　お知らせカテゴリ検索
        SEARCH: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "1" : page;
                    return ({method: "get", params, api: `${url.API_ADMIN_QUESTIONNAIRE}/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //追加//管理者用
        CREATE: {
            REQUEST: [
                (data) => ({method: "put", api: url.API_ADMIN_QUESTIONNAIRE + "/create", data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //編集//管理者用
        UPDATE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_QUESTIONNAIRE + "/update/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //詳細取得用
        VIEW: {
            REQUEST: [
                (uuid) => ({method: "get", api: url.API_ADMIN_QUESTIONNAIRE + "/view/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        //削除//管理者用
        DELETE: {
            REQUEST: [
                (uuid) => {
                    if (Array.isArray(uuid)) {
                        if (uuid.length > 1) {
                            let uuid_array = "";
                            uuid.forEach((value, index) => {
                                uuid_array += value;
                                if (uuid.length > index + 1) {
                                    uuid_array += ",";
                                }
                            });
                            return {
                                method: "delete",
                                api: url.API_ADMIN_QUESTIONNAIRE + "/multiple-delete/" + uuid_array
                            }
                        } else if (uuid.length === 1) {
                            return {method: "delete", api: url.API_ADMIN_QUESTIONNAIRE + "/delete/" + uuid[0]}
                        }
                    } else {
                        return {method: "delete", api: url.API_ADMIN_QUESTIONNAIRE + "/delete/" + uuid}
                    }
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //コピー//管理者用
        COPY: {
            REQUEST: [
                (uuid) => ({method: "get", api: url.API_ADMIN_QUESTIONNAIRE + "/copy/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        //コピー後にViewを再度読み直す
        COPY_REVIEW: {
            REQUEST: [
                (uuid) => {
                    return ({method: "get", api: url.API_ADMIN_QUESTIONNAIRE + "/view/" + uuid})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        ANSWER: {
            SEARCH: {
                REQUEST: [
                    (uuid, data) => ({method: "put", api: url.API_ADMIN_QUESTIONNAIRE + "/answer/" + uuid, data}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,
            },
            RESULT: {
                REQUEST: [
                    (uuid) => ({method: "get", api: url.API_ADMIN_QUESTIONNAIRE + "/answer-result/" + uuid}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,
            },
            //フリーフォームリスト
            FREEFORM: {
                REQUEST: [
                    (uuid, page) => {
                        const pageNum = page === undefined ? "1" : page;
                        return ({method: "get", api: url.API_ADMIN_ANSWER + "/text-result/" + uuid + "/" + pageNum})
                    },
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,
            },
            //添付ファイルリスト
            ATTACHMENT: {
                REQUEST: [
                    (uuid, page) => {
                        const pageNum = page === undefined ? "1" : page;
                        return ({method: "get", api: url.API_ADMIN_ANSWER + "/file-result/" + uuid + "/" + pageNum})
                    },
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,
            }
        },
        // 対象者
        TARGET: {
            REQUEST: [
                (uuid, params, page) => {
                    const pageNum = page === undefined ? "1" : page;
                    return ({
                        method: "get",
                        api: url.API_ADMIN_QUESTIONNAIRE + "/target-users/" + uuid + "/" + pageNum,
                        params
                    })
                },
                () => ({fetch: true})
            ],

            RECEIVE: undefined
        },
        CATEGORY: {
            //パラメータ
            PARAMETER: {
                REQUEST: [
                    () => ({method: "get", api: url.API_ADMIN_QUESTIONNAIRE_CATEGORY}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,
            },
            //検索 　お知らせカテゴリ検索
            SEARCH: {
                REQUEST: [
                    (params) => ({method: "get", api: url.API_ADMIN_QUESTIONNAIRE_CATEGORY, params}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //追加
            CREATE: {
                REQUEST: [
                    (data) => ({method: "put", api: url.API_ADMIN_QUESTIONNAIRE_CATEGORY + "/create", data}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //編集
            UPDATE: {
                REQUEST: [
                    (uuid, data) => ({
                        method: "post",
                        api: url.API_ADMIN_QUESTIONNAIRE_CATEGORY + "/update/" + uuid,
                        data
                    }),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //削除
            DELETE: {
                REQUEST: [
                    (uuid) => ({method: "delete", api: url.API_ADMIN_QUESTIONNAIRE_CATEGORY + "/delete/" + uuid}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            }
        },
    },
    //学年暦関連
    CALENDAR: {
        SEARCH: {
            REQUEST: [
                (params) => {
                    return ({method: "get", params, api: `${url.API_ADMIN_CALENDAR}/`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        CATEGORY: {
            //パラメータ
            PARAMETER: {
                REQUEST: [
                    () => ({method: "get", api: url.API_CALENDAR_CATEGORY}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined,
            },
            //検索
            SEARCH: {
                REQUEST: [
                    (params, page) => {
                        const pageNum = page === undefined ? "" : page;
                        return ({method: "get", params, api: `${url.API_ADMIN_CALENDAR_CATEGORY}/${pageNum}`})
                    },
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //追加
            CREATE: {
                REQUEST: [
                    (data) => ({method: "put", api: url.API_ADMIN_CALENDAR_CATEGORY + "/create", data}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //編集
            UPDATE: {
                REQUEST: [
                    (uuid, data) => ({method: "post", api: url.API_ADMIN_CALENDAR_CATEGORY + "/update/" + uuid, data}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //削除
            DELETE: {
                REQUEST: [
                    (uuid) => ({method: "delete", api: url.API_ADMIN_CALENDAR_CATEGORY + "/delete/" + uuid}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
        }
    },
    FILESHARE: {
        //検索
        FETCH: {
            REQUEST: [
                (params, page, uuid) => {
                    const pageNum = page === undefined ? "1" : page;
                    const generateUuid = typeof uuid === "undefined" ? "" : uuid;
                    return ({method: "get", params, api: `${url.API_ADMIN_FILE_SHARE}/${pageNum}/${generateUuid}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        SEARCH: {
            REQUEST: [
                (params, page, uuid) => {
                    const pageNum = page === undefined ? "1" : page;
                    const generateUuid = typeof uuid === "undefined" ? "" : uuid;
                    return ({
                        method: "get",
                        params,
                        api: `${url.API_ADMIN_FILE_SHARE_RECURSIVE}/${pageNum}/${generateUuid}`
                    })
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        DELETE: {
            REQUEST: [
                (data) => ({method: "post", api: `${url.API_ADMIN_FILE_SHARE}/multiple-delete/`, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        DOWNLOAD: {
            REQUEST: [
                (data) => ({method: "post", api: `${url.API_ADMIN_FILE_SHARE}/prepare-multiple-download/`, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        FILE: {
            CREATE: {
                REQUEST: [
                    (data) => ({method: "put", api: `${url.API_ADMIN_FILE_SHARE_FILE}/create/`, data}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //編集
            UPDATE: {
                REQUEST: [
                    (uuid, data) => ({method: "post", api: `${url.API_ADMIN_FILE_SHARE_FILE}/update/${uuid}`, data}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
        },
        FOLDER: {
            //作成
            CREATE: {
                REQUEST: [
                    (data) => {
                        return ({method: "put", data, api: `${url.API_ADMIN_FILE_SHARE_FOLDER}/create`})
                    },
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
            //編集
            UPDATE: {
                REQUEST: [
                    (uuid, data) => ({method: "post", api: `${url.API_ADMIN_FILE_SHARE_FOLDER}/update/${uuid}`, data}),
                    () => ({fetch: true})
                ],
                RECEIVE: undefined
            },
        },
        SHARED_SETTING: {
            //作成
            REQUEST: [
                () => {
                    return ({method: "get", api: `${url.API_ADMIN_FILE_SHARE_SETTING}/view`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        SHARED_SETTING_UPDATE: {
            REQUEST: [
                (data) => {
                    return ({method: "post", data, api: `${url.API_ADMIN_FILE_SHARE_SETTING}/update`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        }
    },
    //画像アップロード
    IMAGES: {
        REQUEST: [
            (filename, contentType, data) => ({
                method: "put",
                api: url.API_FILE_POST + `/${filename}`,
                data,
                contentType: contentType
            }),
            () => ({fetch: true})
        ],
        RECEIVE: undefined
    },
    //CSVアップロード
    CSV: {
        REQUEST: [
            (filename, contentType, data) => ({
                method: "put",
                api: url.API_FILE_POST + `/${filename}`,
                data,
                contentType: contentType
            }),
            () => ({fetch: true})
        ],
        RECEIVE: undefined
    },
    //文字列アップロード
    TEXT: {
        REQUEST: [
            (data) => ({method: "put", api: url.API_FILE_POST + `/temp_upload.csv`, data, contentType: "text/csv"}),
            () => ({fetch: true})
        ],
        RECEIVE: undefined
    },
    //ユーザ
    USER: {
        //ユーザ検索
        SEARCH: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "" : page;
                    return ({method: "get", params, api: `${url.API_ADMIN_ACTION_USER}/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },//ユーザ詳細
        VIEW: {
            REQUEST: [
                (uuid) => {
                    return ({method: "get", api: `${url.API_ADMIN_ACTION_USER}/view/${uuid}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        SEARCH_TARGET: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "" : page;
                    return ({method: "get", params, api: `${url.API_ADMIN_ACTION_USER}/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        SEARCH_TARGET_USER: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "1" : page;
                    return ({method: "get", params, api: `${url.API_ADMIN_ACTION_USER}/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        //追加
        CREATE: {
            REQUEST: [
                (data) => ({method: "put", api: url.API_ADMIN_ACTION_USER + "/create", data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //編集
        UPDATE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_ACTION_USER + "/update/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //削除
        DELETE: {
            REQUEST: [
                (uuid) => ({method: "delete", api: url.API_ADMIN_ACTION_USER + "/delete/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        CSV: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "" : page;
                    return ({method: "get", params, api: `${url.API_ADMIN_ACTION_USER}/by-csv/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
    },
    //権限管理
    ROLE: {
        //権限管理検索
        SEARCH: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "" : page;
                    return ({method: "get", params, api: `${url.API_ADMIN_ROLE}/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        //追加
        CREATE: {
            REQUEST: [
                (data) => ({method: "put", api: url.API_ADMIN_ROLE + "/create", data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //編集
        UPDATE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_ROLE + "/update/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //削除
        DELETE: {
            REQUEST: [
                (uuid) => ({method: "delete", api: url.API_ADMIN_ROLE + "/delete/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        CSV: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "" : page;
                    log.debug(`${url.API_ADMIN_ROLE}/by-csv/${pageNum}`)
                    return ({method: "get", params, api: `${url.API_ADMIN_ROLE}/by-csv/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
    },

    //グループ
    GROUP: {
        SEARCH: {
            //ユーザ検索
            REQUEST: [
                () => {
                    return ({method: "get", api: `${url.API_ADMIN_GROUP}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },
        //追加 枝にしか付けられないようにする
        CREATE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_GROUP + "/add-child/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //編集
        UPDATE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_GROUP + "/update/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //削除
        DELETE: {
            REQUEST: [
                (uuid) => ({method: "delete", api: url.API_ADMIN_GROUP + "/delete/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },

    },
    //認証プロファイル
    AUTHPROFILE: {
        //検索
        SEARCH: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "" : page;
                    return ({method: "get", params, api: `${url.API_ADMIN_AUTH_PROFILE}/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //追加
        CREATE: {
            REQUEST: [
                (data) => ({method: "put", api: url.API_ADMIN_AUTH_PROFILE + "/create", data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //編集
        UPDATE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_AUTH_PROFILE + "/update/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //削除
        DELETE: {
            REQUEST: [
                (uuid) => ({method: "delete", api: url.API_ADMIN_AUTH_PROFILE + "/delete/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
    },
    //SSO
    SSO: {
        SEARCH: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "" : page;
                    return ({method: "get", params, api: `${url.API_ADMIN_SSO}/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //追加
        CREATE: {
            REQUEST: [
                (data) => ({method: "put", api: url.API_ADMIN_SSO + "/create", data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //編集
        UPDATE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_SSO + "/update/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //削除
        DELETE: {
            REQUEST: [
                (uuid) => ({method: "delete", api: url.API_ADMIN_SSO + "/delete/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
    },
    //権限API
    PERMISSION: {
        REQUEST: [
            (uuid) => {
                return ({method: "get", api: `${url.API_ADMIN_PERMISSION}/view/${uuid}`})
            },
            () => ({fetch: true})
        ],
        RECEIVE: undefined,
        //追加
        CREATE: {
            REQUEST: [
                (data) => ({method: "put", api: url.API_ADMIN_PERMISSION + "/create", data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //編集
        UPDATE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_PERMISSION + "/update/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //削除
        DELETE: {
            REQUEST: [
                (uuid) => ({method: "delete", api: url.API_ADMIN_PERMISSION + "/delete/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        COPY: {
            REQUEST: [
                (uuid) => ({method: "get", api: url.API_ADMIN_PERMISSION + "/copy/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        ADDUSER: {
            REQUEST: [
                (uuid, data) => ({method: "put", data, api: url.API_ADMIN_PERMISSION + "/add-user/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        DELETEUSER: {
            REQUEST: [
                (uuid, data) => ({method: "post", data, api: url.API_ADMIN_PERMISSION + "/delete-user/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        DELETEUSERALL: {
            REQUEST: [
                (uuid) => ({method: "get", api: url.API_ADMIN_PERMISSION + "/delete-all-user/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        ADDALL: {
            REQUEST: [
                (uuid, data) => ({
                    method: "get",
                    params: data,
                    api: url.API_ADMIN_PERMISSION + "/add-all-user/" + uuid
                }),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        ADDALLCSV: {
            REQUEST: [
                (uuid, data) => ({
                    method: "post",
                    api: url.API_ADMIN_PERMISSION + "/add-user-csv/" + uuid + "?file_uuid=" + data
                }),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        }
    },
    //権限対象お気に入りAPI
    MY_TARGET : {
        SEARCH: {
            REQUEST: [
                (uuid) => {
                    return ({method: "get", api: `${url.API_ADMIN_MY_TARGET}/view/${uuid}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },//追加
        CREATE: {
            REQUEST: [
                (data) => ({method: "put", api: url.API_ADMIN_MY_TARGET + "/create", data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //編集
        UPDATE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_MY_TARGET + "/update/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //削除
        DELETE: {
            REQUEST: [
                (uuid) => ({method: "delete", api: url.API_ADMIN_MY_TARGET + "/delete/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //コピー
        COPY: {
            REQUEST: [
                (uuid) => ({method: "get", api: url.API_ADMIN_MY_TARGET + "/copy/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        ADDUSER: {
            REQUEST: [
                (uuid, data) => ({method: "put", data, api: url.API_ADMIN_MY_TARGET + "/add-user/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        DELETEUSER: {
            REQUEST: [
                (uuid, data) => ({method: "post", data, api: url.API_ADMIN_MY_TARGET + "/delete-user/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        DELETEUSERALL: {
            REQUEST: [
                (uuid) => ({method: "get", api: url.API_ADMIN_MY_TARGET + "/delete-all-user/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        ADDALL: {
            REQUEST: [
                (uuid, data) => ({method: "get", params: data, api: url.API_ADMIN_MY_TARGET + "/add-all-user/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        ADDALLCSV: {
            REQUEST: [
                (uuid, data) => ({
                    method: "post",
                    api: url.API_ADMIN_MY_TARGET + "/add-user-csv/" + uuid + "?file_uuid=" + data
                }),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        }
    },
    //権限対象API
    TARGET: {
        SEARCH: {
            REQUEST: [
                (uuid) => {
                    return ({method: "get", api: `${url.API_ADMIN_TARGET}/view/${uuid}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined,
        },//追加
        CREATE: {
            REQUEST: [
                (data) => ({method: "put", api: url.API_ADMIN_TARGET + "/create", data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //編集
        UPDATE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_TARGET + "/update/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //削除
        DELETE: {
            REQUEST: [
                (uuid) => ({method: "delete", api: url.API_ADMIN_TARGET + "/delete/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        //コピー
        COPY: {
            REQUEST: [
                (uuid) => ({method: "get", api: url.API_ADMIN_TARGET + "/copy/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        ADDUSER: {
            REQUEST: [
                (uuid, data) => ({method: "put", data, api: url.API_ADMIN_TARGET + "/add-user/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        DELETEUSER: {
            REQUEST: [
                (uuid, data) => ({method: "post", data, api: url.API_ADMIN_TARGET + "/delete-user/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        DELETEUSERALL: {
            REQUEST: [
                (uuid) => ({method: "get", api: url.API_ADMIN_TARGET + "/delete-all-user/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        ADDALL: {
            REQUEST: [
                (uuid, data) => ({method: "get", params: data, api: url.API_ADMIN_TARGET + "/add-all-user/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        ADDALLCSV: {
            REQUEST: [
                (uuid, data) => ({
                    method: "post",
                    api: url.API_ADMIN_TARGET + "/add-user-csv/" + uuid + "?file_uuid=" + data
                }),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        }
    },
    //ログ
    LOG: {
        //ログ検索（管理者）
        SEARCH: {
            REQUEST: [
                (params, page) => {
                    const pageNum = page === undefined ? "" : page;
                    return ({method: "get", params, api: `${url.API_ADMIN_ACTION_LOG}/${pageNum}`})
                },
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        }
    },
    //レイアウト
    LAYOUT: {
        LOAD: {
            REQUEST: [
                (uuid) => ({method: "get", api: url.API_ADMIN_LAYOUT + "/view/" + uuid}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        },
        SAVE: {
            REQUEST: [
                (uuid, data) => ({method: "post", api: url.API_ADMIN_LAYOUT + "/save/" + uuid, data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        }
    },
    //マスターパスワードの変更
    PASSWORD: {
        CHANGE: {
            REQUEST: [
                (data) => ({method: "post", api: url.API_ADMIN_CHANGE_MASTER_PASS + "/update", data}),
                () => ({fetch: true})
            ],
            RECEIVE: undefined
        }
    },

});


export const setParamMenu = createAction("PARAM/MENU");

export const session = createAction('SESSION');

export const timer = createAction("TIMER");

export const setPreviewURL = createAction("PREVIEW/URL")


export const errors = createAction('ERRORS');
export const successes = createAction('SUCCESSES');


export const Config = createActions({
    SCREEN: (screenHeight, screenWidth) => ({screenHeight, screenWidth}),
    AUTH_PROFILE: (data) => data,
});



